import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import { Link, useNavigate } from 'react-router-dom'

const IndividaulForm = ({ isSocial }) => {

   const navigate = useNavigate();
   const [searchParams] = useSearchParams();

   const [formData, setFormData] = useState({
      social_token: '',
      identified: '',
      password: '',
      name: '',
      phone: '',
      marketing: 0,
      marketing_type: [],
   })

   // 이메일, 네이버, 카카오 로그인 구분
   const socialType = searchParams.get('social_type');
   let socialTypeInfo = socialType ? socialType : 0;

   // 소셜 로그인 시 자동입력
   const socialToken = searchParams.get('social_token');
   const email = searchParams.get('email');
   const phone = searchParams.get('phone');
   const name = searchParams.get('name');

   useEffect(() => {
      if (socialToken && email && phone) {
         setFormData(({ password, ...rest }) => ({
            ...rest,
            social_token: socialToken,
            identified: email,
            phone: phone,
            name: name
         }));
      }
   }, [])


   const handleChange = (e) => {
      const { name, value, type, checked, dataset } = e.target
      if (type === 'checkbox') {
         setFormData({
            ...formData,
            [name]: checked ? 1 : 0
         })
         handleCheck(dataset.item)
      } else {
         setFormData({
            ...formData,
            [name]: value
         })
      }
   }

   // check
   const [isAllChecked, setIsAllChecked] = useState(false);
   const [checkedItems, setCheckedItems] = useState({
      item1: false,
      item2: false,
      item3: false,
      item4: false,
      item5: false,
      item6: false,
   });


   // sns 동의 정보 formData에 저장
   useEffect(() => {
      // 체크된 항목만 배열에 추가
      let updatedMarketingType = '';
      if (checkedItems.item4) updatedMarketingType += (updatedMarketingType ? '&nbsp;' : '') + 'SMS/알림톡';
      if (checkedItems.item5) updatedMarketingType += (updatedMarketingType ? '&nbsp;' : '') + '이메일';
      if (checkedItems.item6) updatedMarketingType += (updatedMarketingType ? '&nbsp;' : '') + '전화';


      setFormData(prevFormData => ({
         ...prevFormData,
         marketing_type: updatedMarketingType
      }));

   }, [checkedItems.item4, checkedItems.item5, checkedItems.item6]);

   // 마케팅 수신 동의시 formData 값 변경
   useEffect(() => {
      if (checkedItems.item3) {
         setFormData(prev => ({
            ...prev,
            marketing: 1
         }))
      } else {
         setFormData(prev => ({
            ...prev,
            marketing: 0
         }))
      }
   }, [checkedItems.item3])

   const handleAllCheck = () => {
      const newCheckedState = !isAllChecked;
      setIsAllChecked(newCheckedState);
      setCheckedItems({
         item1: newCheckedState,
         item2: newCheckedState,
         item3: newCheckedState,
         item4: newCheckedState,
         item5: newCheckedState,
         item6: newCheckedState,
      });
   };

   // 전체선택
   const handleCheck = (item) => {
      setCheckedItems((prev) => {
         const newCheckedItems = { ...prev, [item]: !prev[item] };

         // item3 체크 시 item4, item5, item6도 체크
         if (item === 'item3') {
            newCheckedItems.item4 = true;
            newCheckedItems.item5 = true;
            newCheckedItems.item6 = true;
         }

         // item3 체크 해제 시 item4, item5, item6도 체크 해제
         if (!newCheckedItems.item3) {
            newCheckedItems.item4 = false;
            newCheckedItems.item5 = false;
            newCheckedItems.item6 = false;
         }

         return newCheckedItems;
      });
      // sns 동의
   };

   // 비밀번호 재확인 
   const [matchingPw, setMatchingPw] = useState(false);
   const handleCheckPw = (e) => {
      if (formData.password === e.target.value) {
         setMatchingPw(true)
      } else {
         setMatchingPw(false)
      }
   }

   // 회원가입 데이터 전송
   const SendData = async () => {

      if (!isSocial && !matchingPw) {
         alert('비밀번호가 일치하지 않습니다.')
         return false
      };

      console.log('formData', formData)
      try {
         const response = await axios.post(`${process.env.REACT_APP_API_URL}/join/${socialTypeInfo}/email`, formData)
         alert(response.data.msg)
         if (response.status === 200) {
            navigate('/');
         }
      } catch (error) {
         console.error(error)
         alert(error.response.data.msg)
      }
   }

   // 휴대폰 인증
   const memberType = 0; // 일반인 0, 상담사 1
   const certType = isSocial ? 1 : 0;
   const [verificationStep, setVerificationStep] = useState(0);

   // 휴대폰 번호를 입력했을 때만 step1로 변경
   useEffect(() => {
      if (formData.phone !== '') {
         setVerificationStep(1)
      } else {
         setVerificationStep(0)
      }
   }, [formData.phone])

   // 현재 step에 맞는 class명과 text
   const currentVerificationStep = () => {
      if (verificationStep === 0) {
         return { className: 'disable', text: '인증번호 받기' }
      } else if (verificationStep === 1) {
         return { className: '', text: '인증번호 받기' }
      } else if (verificationStep === 2) {
         return { className: 'black', text: '인증번호 재발송' }
      } else if (verificationStep === 3) {
         return { className: 'gray', text: '인증완료' }
      }
   }

   // 인증번호
   const [verificationCode, setVerificationCode] = useState('')

   // 휴대폰 인증번호 전송 API
   const getVerificationCode = async () => {
      if (verificationStep === 0 || verificationStep === 3) return false;
      try {
         await axios.post(`${process.env.REACT_APP_API_URL}/send/cert/${memberType}/${certType}`, { phone: formData.phone })
         setVerificationStep(2)
      } catch (error) {
         console.error(error)
         alert(error.response.data.msg)
      }
   }
   // 휴대폰 인증번호 확인 API
   const verifyAuth = async () => {
      try {
         const response = await axios.post(`${process.env.REACT_APP_API_URL}/check/cert/${memberType}/${certType}`, { phone: formData.phone, cert_code: verificationCode })
         console.log('인증번호 확인', response)
         if (response.status === 200) {
            setVerificationStep(3)
         }
      } catch (error) {
         console.error(error)
         alert(error.response.data.msg)
      }
   }

   // 인증번호 입력시 값 변경
   const handleVerificationCode = (e) => {
      setVerificationCode(e.target.value)
   }

   console.log('formdata', formData)

   return (
      <div className="subpage">
         <section className="user-section">
            <div className="user-wrap">
               <div className="title-wrap row-group">
                  <h2 className="title">
                     회원가입
                  </h2>
                  <p className="txt">
                     마인드 인사이트를 통해 진정한 '나'를 발견하세요.
                  </p>
               </div>
               <div className="guide-txt">
                  <span className="red">*</span> 표시는 필수입력 항목입니다.
               </div>
               <div className="form-wrap account-form-wrap row-group">
                  <div className="form-list row-group border">
                     <div className="form-item row-group">
                        <div className="item-default">
                           이메일
                           <span className="red">*</span>
                        </div>
                        <div className="item-user">
                           <input
                              onChange={handleChange}
                              value={formData.identified}
                              type="text"
                              className="form-input"
                              name='identified'
                              placeholder='이메일(아이디)'
                              required={true}
                           />
                        </div>
                     </div>
                     {isSocial ? (
                        ''
                     ) : (
                        <div className="form-item row-group">
                           <div className="item-default">
                              비밀번호
                              <span className="red">*</span>
                           </div>
                           <div className="item-user">
                              <div className="form-input-wrap row-group">
                                 <input
                                    onChange={handleChange}
                                    type="password"
                                    className="form-input"
                                    placeholder="비밀번호"
                                    name='password'
                                    required={true}
                                 />
                                 <input
                                    onChange={handleCheckPw}
                                    type="password"
                                    className="form-input"
                                    placeholder="비밀번호 재확인"
                                 />
                                 <p className={`${matchingPw ? '' : 'hide-g'} guide-txt blue`}>
                                    비밀번호가 일치합니다.
                                 </p>
                                 <p className={`${matchingPw ? 'hide-g' : ''} guide-txt red`}>
                                    비밀번호가 일치하지 않습니다.
                                 </p>
                              </div>
                           </div>
                        </div>
                     )}
                     <div className="form-item row-group">
                        <div className="item-default">
                           이름
                           <span className="red">*</span>
                        </div>
                        <div className="item-user">
                           <input
                              onChange={handleChange}
                              value={formData.name}
                              type="text"
                              className="form-input"
                              placeholder="이름"
                              name='name'
                              required={true}
                           />
                        </div>
                     </div>
                     <div className="form-item row-group">
                        <div className="item-default">
                           휴대폰 번호
                           <span className="red">*</span>
                        </div>
                        <div className="item-user">
                           <div className="form-input-wrap row-group">
                              <div className="form-btn-wrap col-group">
                                 <input
                                    value={formData.phone}
                                    onChange={handleChange}
                                    onWheel={event => (event.target).blur()}
                                    type="number"
                                    className="form-input"
                                    placeholder="휴대폰 번호"
                                    name='phone'
                                    required={true}
                                 />
                                 <button
                                    onClick={getVerificationCode}
                                    className={`${currentVerificationStep().className} form-btn`}
                                 >
                                    {currentVerificationStep().text}
                                 </button>
                              </div>

                              {/* 인증번호 받기 버튼 클릭 시 활성화 / 인증 완료 후 숨김 */}
                              <div className={`${verificationStep === 2 ? '' : 'hide-g'} form-input-wrap row-group`}>
                                 <div className="form-btn-wrap col-group">
                                    <input
                                       onChange={handleVerificationCode}
                                       value={verificationCode}
                                       type="number"
                                       className="form-input"
                                       placeholder="인증번호"
                                    />
                                    <button
                                       onClick={verifyAuth}
                                       className={`${verificationCode !== '' ? '' : 'disable'} form-btn `}
                                    >
                                       인증확인
                                    </button>
                                 </div>

                                 {/* //인증번호 받기 버튼 클릭 시 활성화 / 인증 완료 후 숨김 */}
                                 <p className="guide-txt red">
                                    휴대폰으로 발송된 인증번호를 입력해주세요.
                                 </p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="form-item row-group">
                     <div className="item-default">
                        이용약관
                     </div>
                     <div className="item-user">
                        <div className="join-agree-wrap row-group">
                           <div className="join-agree-item join-agree-item-all col-group">
                              <label htmlFor="agree_0">
                                 <input
                                    checked={isAllChecked}
                                    onChange={handleAllCheck}
                                    type="checkbox"
                                    className="form-checkbox"
                                    id="agree_0"
                                 />
                                 <div className="checked-item col-group">
                                    <div className="icon">
                                       <i className="xi-check"></i>
                                    </div>
                                    <p className="txt">
                                       모두 동의합니다.
                                    </p>
                                 </div>
                              </label>
                           </div>
                           <div className="join-agree-item col-group">
                              <label htmlFor="agree_1">
                                 <input
                                    checked={checkedItems.item1}
                                    onChange={() => handleCheck('item1')}
                                    type="checkbox"
                                    className="form-checkbox"
                                    id="agree_1"
                                    required={true}
                                 />
                                 <div className="checked-item col-group">
                                    <div className="icon">
                                       <i className="xi-check"></i>
                                    </div>
                                    <p className="txt">
                                       [필수] 서비스이용약관에 동의합니다.
                                    </p>
                                 </div>
                              </label>
                              <a href="privacy_policy.html" target="_blank" className="more-btn">
                                 보기
                              </a>
                           </div>
                           <div className="join-agree-item col-group">
                              <label htmlFor="agree_2">
                                 <input
                                    checked={checkedItems.item2}
                                    onChange={() => handleCheck('item2')}
                                    type="checkbox"
                                    className="form-checkbox"
                                    id="agree_2"
                                    required={true}
                                 />
                                 <div className="checked-item col-group">
                                    <div className="icon">
                                       <i className="xi-check"></i>
                                    </div>
                                    <p className="txt">
                                       [필수] 개인정보처리방침 약관에 동의합니다.
                                    </p>
                                 </div>
                              </label>
                              <a href="privacy_policy.html" target="_blank" className="more-btn">
                                 보기
                              </a>
                           </div>
                           <div className="join-agree-item col-group">
                              <label htmlFor="agree_3">
                                 <input
                                    checked={checkedItems.item3}
                                    onChange={handleChange}
                                    type="checkbox"
                                    className="form-checkbox"
                                    id="agree_3"
                                    name='marketing'
                                    data-item='item3'
                                 />
                                 <div className="checked-item col-group">
                                    <div className="icon">
                                       <i className="xi-check"></i>
                                    </div>
                                    <p className="txt">
                                       [선택] 마케팅 정보 수신에 동의합니다.
                                    </p>
                                 </div>
                              </label>
                              <a href="privacy_policy.html" target="_blank" className="more-btn">
                                 보기
                              </a>
                           </div>
                           <div className="checked-item-sub col-group">
                              <label htmlFor="agree_4">
                                 <input
                                    checked={checkedItems.item4}
                                    onChange={() => handleCheck('item4')}
                                    type="checkbox"
                                    className="form-checkbox"
                                    id="agree_4"
                                 />
                                 <div className="checked-item col-group">
                                    <div className="icon">
                                       <i className="xi-check"></i>
                                    </div>
                                    <p className="txt">
                                       SMS/알림톡
                                    </p>
                                 </div>
                              </label>
                              <label htmlFor="agree_5">
                                 <input
                                    checked={checkedItems.item5}
                                    onChange={() => handleCheck('item5')}
                                    type="checkbox"
                                    className="form-checkbox"
                                    id="agree_5"
                                 />
                                 <div className="checked-item col-group">
                                    <div className="icon">
                                       <i className="xi-check"></i>
                                    </div>
                                    <p className="txt">
                                       이메일
                                    </p>
                                 </div>
                              </label>
                              <label htmlFor="agree_6">
                                 <input
                                    checked={checkedItems.item6}
                                    onChange={() => handleCheck('item6')}
                                    type="checkbox"
                                    className="form-checkbox"
                                    id="agree_6"
                                 />
                                 <div className="checked-item col-group">
                                    <div className="icon">
                                       <i className="xi-check"></i>
                                    </div>
                                    <p className="txt">
                                       전화
                                    </p>
                                 </div>
                              </label>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="mp-section-footer border">
                     <button
                        onClick={SendData}
                        className="form-footer-btn disable"
                     > {/* 필수칸 모두 입력 시 disable 클래스 삭제 */}
                        회원가입
                     </button>
                  </div>
               </div>
            </div >
         </section >
      </div >
   )
}

export default IndividaulForm