import React, { useEffect } from 'react'
import { images } from '../assets/images';

const TopMenu = () => {

   useEffect(() => {

      const topScrollBtn = document.querySelector('.Top_Scroll_btn');

      if (topScrollBtn) {
         topScrollBtn.addEventListener('click', function () {
            window.scrollTo({ top: 0, behavior: 'smooth' });
         });

         document.addEventListener('scroll', function () {
            if (document.documentElement.scrollTop > 88) {
               topScrollBtn.classList.add('active');
            } else {
               topScrollBtn.classList.remove('active');
            }

            const scrollPercent = (window.scrollY / (document.documentElement.scrollHeight - window.innerHeight)) * 100;
            const color = getColorFromPercentage(scrollPercent);

            const progressBar = topScrollBtn.querySelector('.progress_bar');
            if (progressBar) {
               progressBar.style.background = `conic-gradient(${color} 0% ${scrollPercent}%, #fff ${scrollPercent}% 100%)`;
            }
         });
      }

      function getColorFromPercentage(percentage) {
         const startColor = [0, 117, 74]; // RGB 값으로 작성해야함!
         const endColor = [0, 117, 74];

         const color = startColor.map((start, i) => {
            return Math.round(start + (endColor[i] - start) * (percentage / 100));
         });

         return `rgb(${color.join(',')})`;
      }
   }, [])
   return (
      <div className="top-btn-wrap">
         <a href="mind_insight_test/index.html" target="_blank" className="btn test-btn">
            <div className="hover-btn">
               <p>검사하기</p>
            </div>
            <div className="basic-btn">
               <img src={images.icon_pen} alt="" />
            </div>
         </a>
         <a href="http://pf.kakao.com/_wZlxkG" target="_blank" className="btn inquiry-btn" rel='noreferrer'>
            <div className="hover-btn">
               <p>문의하기</p>
            </div>
            <div className="basic-btn">
               <img src={images.icon_message} alt="" />
            </div>
         </a>
         <button className="Top_Scroll_btn">
            <i className="xi-arrow-up"></i>
            <div className="progress_bar"></div>
         </button>
      </div>

   )
}

export default TopMenu