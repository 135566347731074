import React from 'react'

const OrderDone = () => {
   return (
      <div className="subpage order">
         <div className="routeBox">
            <div className="container">
               <div className="le-wrap">
                  <i className="icon"></i>
                  <p className="title">결제완료</p>
               </div>
               <div className="ri-wrap">
                  <p className="route-title">장바구니</p>
                  <i className="route-icon"></i>
                  <p className="route-title">주문/결제</p>
                  <i className="route-icon"></i>
                  <p className="route-title now">완료</p>
               </div>
            </div>
         </div>
         <section className="user-section">
            <div className="container">
               <div className="result-wrap">
                  <div className="result-inner">
                     <i className="icon green"></i>
                     <p className="result-title">
                        <span className="green">결제</span>가 완료되었습니다. 감사합니다.
                     </p>
                     <div className="sub-wrap">
                        <p className="sub">
                           마인드 인사이트는 심리검사 상품으로 구매 후 직접 사용하거나 마이페이지에서 선물하기가 가능합니다. <br />
                           자세한 내용은 <a href="" className="link">자주 하는 질문</a>을 확인해 주세요.
                        </p>
                     </div>
                  </div>
                  <div className="btn-wrap col-group">
                     <a href="login.html" className="btn">메인 화면으로</a>
                     <a href="login.html" className="btn green">검사코드 사용하기</a>
                  </div>
               </div>
            </div>
         </section>
      </div>
   )
}

export default OrderDone