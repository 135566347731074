import React from 'react'

// Images, Videos
import { images } from '../../../../assets/images'
import Pagination from '../../../../components/common/Pagination'

const TestResult = () => {
   return (
      <div class="myPageContent">
         <div class="mp-main-title-wrap border col-group">
            <h2 class="mp-main-title">
               <span class="mb">My 정보 <i></i></span>
               심리검사 진행/결과
            </h2>
            <div class="search-wrap">
               <input type="text" class="search-input" placeholder="이름을 입력하세요" />
               <button class="search-btn">
                  <i></i>
               </button>
            </div>
         </div>

         <div class="test-result-list row-group">
            <div class="test-result-item col-group">
               <div class="img">
                  <img src={images.academia_item_04} alt="" />
               </div>
               <div class="txt-wrap row-group">
                  <div class="title-group row-group">
                     <div class="name-group col-group">
                        <p class="name">
                           홍길동
                        </p>
                        <p class="sub-title">
                           마인드 인사이트
                        </p>
                     </div>
                     <div class="detail-list col-group">
                        <div class="detail-item col-group">
                           <i class="icon"></i>
                           <p class="txt">
                              1999.09.09
                           </p>
                        </div>
                        <div class="detail-item col-group">
                           <i class="icon"></i>
                           <p class="txt">
                              01012341234
                           </p>
                        </div>
                        <div class="detail-item col-group">
                           <i class="icon"></i>
                           <p class="txt">
                              email123543@email.com
                           </p>
                        </div>
                     </div>
                  </div>
                  <div class="test-result-step">
                     <div class="test-title-group col-group">
                        <p class="test-title">검사 진행률</p>
                        <p class="test-title green">50%</p>
                     </div>
                     <div class="test-step-group col-group">
                        <div class="test-step-item row-group active"> {/* 진행 완료 시 active 클래스 추가 */}
                           <div class="item-user"></div>
                           <p class="item-default">STEP 01</p>
                        </div>
                        <div class="test-step-item row-group active"> {/* 진행 완료 시 active 클래스 추가 */}
                           <div class="item-user"></div>
                           <p class="item-default">STEP 02</p>
                        </div>
                        <div class="test-step-item row-group active">
                           <div class="item-user"></div>
                           <p class="item-default">STEP 03</p>
                        </div>
                        <div class="test-step-item row-group active">
                           <div class="item-user"></div>
                           <p class="item-default">STEP 04</p>
                        </div>
                     </div>
                  </div>
               </div>
               <a href="" class="btn active"> {/* 진행률 100% 일 시 active 클래스 추가 */}
                  결과보기
               </a>
            </div>
            <div class="test-result-item col-group">
               <div class="img">
                  <img src={images.academia_item_04} alt="" />
               </div>
               <div class="txt-wrap row-group">
                  <div class="title-group row-group">
                     <div class="name-group col-group">
                        <p class="name">
                           홍길동
                        </p>
                        <p class="sub-title">
                           마인드 인사이트
                        </p>
                     </div>
                     <div class="detail-list col-group">
                        <div class="detail-item col-group">
                           <i class="icon"></i>
                           <p class="txt">
                              1999.09.09
                           </p>
                        </div>
                        <div class="detail-item col-group">
                           <i class="icon"></i>
                           <p class="txt">
                              01012341234
                           </p>
                        </div>
                        <div class="detail-item col-group">
                           <i class="icon"></i>
                           <p class="txt">
                              email123543@email.com
                           </p>
                        </div>
                     </div>
                  </div>
                  <div class="test-result-step">
                     <div class="test-title-group col-group">
                        <p class="test-title">검사 진행률</p>
                        <p class="test-title green">50%</p>
                     </div>
                     <div class="test-step-group col-group">
                        <div class="test-step-item row-group active"> {/* 진행 완료 시 active 클래스 추가 */}
                           <div class="item-user"></div>
                           <p class="item-default">STEP 01</p>
                        </div>
                        <div class="test-step-item row-group active"> {/* 진행 완료 시 active 클래스 추가 */}
                           <div class="item-user"></div>
                           <p class="item-default">STEP 02</p>
                        </div>
                        <div class="test-step-item row-group">
                           <div class="item-user"></div>
                           <p class="item-default">STEP 03</p>
                        </div>
                        <div class="test-step-item row-group">
                           <div class="item-user"></div>
                           <p class="item-default">STEP 04</p>
                        </div>
                     </div>
                  </div>
               </div>
               <a href="" class="btn"> {/* 진행률 100% 일 시 active 클래스 추가 */}
                  결과보기
               </a>
            </div>
         </div>
         <Pagination />
      </div>
   )
}

export default TestResult