import React from 'react'
import { Outlet } from 'react-router-dom'

// components
import Header from './components/Header';
import Footer from './components/Footer';
import TopMenu from './components/TopMenu';

// style
import 'swiper/css';
import 'swiper/css/pagination';
import './css/common.css'
import './css/style.css'

const Root = () => {
  return (
    <div>
      <Header />
      <Outlet />
      <TopMenu />
      <Footer />
    </div>
  )
}

export default Root