import React, { useState } from 'react'

const FixedCart = ({ isFixed, purchaseInfo }) => {

   const [isVisible, setIsVisible] = useState(true);
   const [selectIndex, setSelectIndex] = useState(false);
   const [selectedItem, setSelectedItem] = useState("필수 옵션 선택");

   const handleSelect = () => {
      setSelectIndex(prev => !prev)
   }
   const selectItem = (selectedItem) => {
      setSelectedItem(selectedItem)
   }
   const handleVisible = () => {
      setIsVisible(prev => !prev)
   }

   return (
      <div className={`right fixed ${isFixed ? "active" : ""} ${!isVisible ? "close" : ""}`}>
         <div className="right-con">
            <div className="con-wrap">
               <div
                  onClick={() => { handleVisible() }}
                  className="course-toggle-btn"
               >
                  <i></i>
               </div>
               <div className="title-wrap">
                  <p className="sub-title green">{purchaseInfo.category}</p>
                  <h2 className="title">{purchaseInfo.title}</h2>
               </div>
               <div className="detail-item price-box col-group">
                  <p className="item-default">
                     기본가
                  </p>
                  <div className="item-user col-group">
                     <p className="percent">{purchaseInfo.price.discount}%</p> {/* 할인 퍼센트 */}
                     <p className="price">{(purchaseInfo.price.netPrice * purchaseInfo.price.discount / 100).toLocaleString()}원</p> {/* 할인된 가격 */}
                     <p className="before">{purchaseInfo.price.netPrice.toLocaleString()}원</p> {/* 기존가격 */}
                  </div>
               </div>
               {purchaseInfo.options &&
                  <div
                     onClick={() => handleSelect()}
                     className={`course-select-wrap ${selectIndex === true ? "active" : ''}`}
                  >
                     <div className="course-select col-group">
                        <div className="select-value">{selectedItem}</div>
                     </div>
                     <div className="option-list row-group">
                        {purchaseInfo.options.map((item, index) => (
                           <div
                              onClick={() => selectItem(item)}
                              className="option-item"
                              key={index}
                           >
                              {item}
                           </div>
                        ))}
                     </div>
                  </div>
               }
               <div className="prod-option-list row-group">
                  <div className="prod-option-item row-group">
                     <p className="prod-option-title">
                        마인드 인사이트 20개 묶음 상품 50%할인!(환불불가)
                     </p>
                     <div className="prod-option-group col-group">
                        <div className="prod-option-amount-group col-group">
                           <button className="prod-option-amount-btn amount-minus">
                              <i className="xi-minus"></i>
                           </button>
                           <span className="prod-option-amount-count">
                              1
                           </span>
                           <button className="prod-option-amount-btn amount-plus">
                              <i className="xi-plus"></i>
                           </button>
                        </div>
                        <div className="col-group">
                           <p className="prod-option-price">
                              <strong>50,000</strong>원
                           </p>
                           <i className="xi-close-thin del-btn"></i>
                        </div>
                     </div>
                  </div>
                  <div className="prod-option-item row-group">
                     <p className="prod-option-title">
                        마인드 인사이트 + 마인드 리포트 (+ 20,000원)
                     </p>
                     <div className="prod-option-group col-group">
                        <div className="prod-option-amount-group col-group">
                           <button className="prod-option-amount-btn amount-minus">
                              <i className="xi-minus"></i>
                           </button>
                           <span className="prod-option-amount-count">
                              1
                           </span>
                           <button className="prod-option-amount-btn amount-plus">
                              <i className="xi-plus"></i>
                           </button>
                        </div>
                        <div className="col-group">
                           <p className="prod-option-price">
                              <strong>140,000</strong>원
                           </p>
                           <i className="xi-close-thin del-btn"></i>
                        </div>
                     </div>
                  </div>
               </div>

               <div className="price-box price-total-box col-group">
                  <p className="item-default">
                     총 상품 금액
                  </p>
                  <div className="item-user col-group">
                     <p className="amount">총 수량 3개</p>
                     <p className="price red">1,230,000원</p>
                  </div>
               </div>
            </div>
            <div className="btn-wrap col-group">
               <button className="btn share-btn" type="button">
                  <i></i>
               </button>
               <button className="btn submit-btn confirm">
                  구매하기
               </button>
            </div>
         </div>
      </div>
   )
}

export default FixedCart