import React, { useEffect } from 'react'
import $ from 'jquery'

const Faq = ({ faqInfo }) => {

   useEffect(() => {
      //자주묻는질문
      $('.q-box').click(function () {
         $(this).parent('.qna-item').toggleClass('active');
      });
   }, [])

   return (
      <div className="container w1280">
         <h3 className="sec-title">
            자주 하는 질문
         </h3>
         <div className="sec-con-wrap">
            <div className="qna-list row-group">
               {
                  faqInfo.map((faqInfo, index) => (
                     <div className="qna-item" key={index}>
                        <div className="q-box col-group">
                           <p className="txt">
                              <span className="icon">Q</span>
                              {faqInfo.question}
                           </p>
                           <div className="more-btn">
                              <i className="xi-angle-down"></i>
                           </div>
                        </div>
                        <div className="a-box">
                           <p className="txt">{faqInfo.answer}</p>
                        </div>
                     </div>
                  ))
               }
               {/* <div className="qna-item">
                  <div className="q-box col-group">
                     <p className="txt">
                        <span className="icon">Q</span>
                        누구나 수강가능한가요?
                     </p>
                     <div className="more-btn">
                        <i className="xi-angle-down"></i>
                     </div>
                  </div>
                  <div className="a-box">
                     <p className="txt">마인드 인사이트 결과를 더 심층적으로 분석하고 이야기 나누며, ‘나’를 더 깊이 있게 이해하는 과정입니다.
                        이 과정에는 한국영성심리분석상담학회에서 전문적으로 수련한 상담사들이 함께 합니다.</p>
                  </div>
               </div>
               <div className="qna-item">
                  <div className="q-box col-group">
                     <p className="txt">
                        <span className="icon">Q</span>
                        누구나 수강가능한가요?
                     </p>
                     <div className="more-btn">
                        <i className="xi-angle-down"></i>
                     </div>
                  </div>
                  <div className="a-box">
                     <p className="txt">마인드 인사이트 결과를 더 심층적으로 분석하고 이야기 나누며, ‘나’를 더 깊이 있게 이해하는 과정입니다.
                        이 과정에는 한국영성심리분석상담학회에서 전문적으로 수련한 상담사들이 함께 합니다.</p>
                  </div>
               </div>
               <div className="qna-item">
                  <div className="q-box col-group">
                     <p className="txt">
                        <span className="icon">Q</span>
                        누구나 수강가능한가요?
                     </p>
                     <div className="more-btn">
                        <i className="xi-angle-down"></i>
                     </div>
                  </div>
                  <div className="a-box">
                     <p className="txt">마인드 인사이트 결과를 더 심층적으로 분석하고 이야기 나누며, ‘나’를 더 깊이 있게 이해하는 과정입니다.
                        이 과정에는 한국영성심리분석상담학회에서 전문적으로 수련한 상담사들이 함께 합니다.</p>
                  </div>
               </div> */}
            </div>
         </div>
      </div>
   )
}

export default Faq