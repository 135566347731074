import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import { Link } from 'react-router-dom'

// Hooks
import { inputPhoneNum, currentVerificationStep, getVerificationCode, verifyAuth, handleVerificationCode } from '../../../hooks/VerificationCode';

const IndexPage = ({ isSocial, setCurrentPage, findAccount }) => {

   const [searchParams] = useSearchParams();

   // 이메일, 네이버, 카카오 로그인 구분
   const socialType = searchParams.get('social_type');
   let socialTypeInfo = socialType ? socialType : 0;

   // 소셜 로그인 시 자동입력
   const socialToken = searchParams.get('social_token');
   const email = searchParams.get('email');
   const phone = searchParams.get('phone');

   const [formData, setFormData] = useState({
      social_token: '',
      identified: '',
      password: '',
      name: '',
      phone: '',
      birth: '',
      counsel_type: '',
      intro_text: '',
      briefHistoryArr: '',
      proveArr: '',
      marketing: '',
      marketing_type: ''
   });

   // input 입력 시 formData에 저장
   const handleChange = (e) => {
      const { name, value } = e.target;
   }

   // 소셜 토큰, 소셜 타입 formData에 저장
   useEffect(() => {
      setFormData((prev) => ({
         ...prev,
         social_token: socialToken,
         identified: email,
         phone: phone,
      }))
   }, [])


   // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
   inputPhoneNum(formData, setVerificationStep)

   // 휴대폰 인증
   const memberType = 1; // 일반인 0, 상담사 1
   const certType = isSocial ? 1 : findAccount === 'id' ? 2 : findAccount === 'pw' ? 3 : 0;
   const [verificationStep, setVerificationStep] = useState(0);

   // 인증번호
   const [verificationCode, setVerificationCode] = useState('')


   return (
      <div className="subpage">
         <section className="user-section">
            <div className="user-wrap">
               <div className="title-wrap row-group">
                  <h2 className="title">
                     상담사 회원가입
                  </h2>
                  <p className="txt">
                     마인드 인사이트 상담사 등록을 위한 기본 정보를 입력해 주세요.
                  </p>
                  <div className="join-tab-list col-group">
                     <div className="join-tab active">
                        1. 기본 정보 입력
                     </div>
                     <div className="join-tab">
                        2. 자격증/학과 입력
                     </div>
                     <div className="join-tab">
                        3. 신청완료
                     </div>
                  </div>
               </div>

               <div className="guide-txt">
                  <span className="red">*</span> 표시는 필수입력 항목입니다.
               </div>

               <div className="form-wrap account-form-wrap row-group">
                  <div className="form-list row-group">
                     <div className="form-item row-group">
                        <div className="item-default">
                           이메일
                           <span className="red">*</span>
                        </div>
                        <div className="item-user">
                           <input
                              value={formData.identified}
                              type="text"
                              className="form-input"
                              placeholder="이메일(아이디)"
                           />
                        </div>
                     </div>
                     {!isSocial &&
                        <div className="form-item row-group">
                           <div className="item-default">
                              비밀번호
                              <span className="red">*</span>
                           </div>
                           <div className="item-user">
                              <div className="form-input-wrap row-group">
                                 <input type="password" className="form-input" placeholder="비밀번호" />
                                 <input type="password" className="form-input" placeholder="비밀번호 재확인" />
                                 <p className="guide-txt blue">
                                    비밀번호가 일치합니다.
                                 </p>
                                 <p className="guide-txt red">
                                    비밀번호가 일치하지 않습니다.
                                 </p>
                              </div>
                           </div>
                        </div>}
                     <div className="form-item row-group">
                        <div className="item-default">
                           이름
                           <span className="red">*</span>
                        </div>
                        <div className="item-user">
                           <input type="text" className="form-input" placeholder="이름" />
                        </div>
                     </div>
                     <div className="form-item row-group">
                        <div className="item-default">
                           휴대폰 번호
                           <span className="red">*</span>
                        </div>
                        <div className="item-user">
                           <div className="form-input-wrap row-group">
                              <div className="form-btn-wrap col-group">
                                 <input
                                    value={formData.phone}
                                    onChange={handleChange}
                                    onWheel={event => (event.target).blur()}
                                    type="number"
                                    className="form-input"
                                    placeholder="휴대폰 번호"
                                    name='phone'
                                    required={true}
                                 />
                                 <button
                                    onClick={getVerificationCode(memberType, verificationStep, setVerificationStep, certType, formData)}
                                    className={`${currentVerificationStep(verificationStep).className} form-btn`}
                                 >
                                    {currentVerificationStep(verificationStep)}
                                 </button>
                              </div>

                              {/* 인증번호 받기 버튼 클릭 시 활성화 / 인증 완료 후 숨김 */}
                              <div className={`${verificationStep === 2 ? '' : 'hide-g'} form-input-wrap row-group`}>
                                 <div className="form-btn-wrap col-group">
                                    <input
                                       onChange={handleVerificationCode(setVerificationCode)}
                                       value={verificationCode}
                                       type="number"
                                       className="form-input"
                                       placeholder="인증번호"
                                    />
                                    <button
                                       onClick={verifyAuth(memberType, certType, formData, verificationCode, setVerificationStep)}
                                       className={`${verificationCode !== '' ? '' : 'disable'} form-btn `}
                                    >
                                       인증확인
                                    </button>
                                 </div>

                                 {/* //인증번호 받기 버튼 클릭 시 활성화 / 인증 완료 후 숨김 */}
                                 <p className="guide-txt red">
                                    휴대폰으로 발송된 인증번호를 입력해주세요.
                                 </p>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="form-item row-group">
                        <div className="item-default">
                           생년월일
                           <span className="red">*</span>
                        </div>
                        <div className="item-user">
                           <input type="date" className="form-date" />
                        </div>
                     </div>
                     <div className="form-item row-group">
                        <div className="item-default">
                           상담 분야
                           <span className="red">*</span>
                           <p className="guide-txt">
                              중복선택이 가능합니다.
                           </p>
                        </div>
                        <div className="item-user">
                           <div className="form-label-wrap half col-group">
                              <label htmlFor="field_01">
                                 <input type="checkbox" className="form-checkbox" id="field_01" />
                                 <div className="checked-item col-group">
                                    <div className="icon">
                                       <i className="xi-check"></i>
                                    </div>
                                    <p className="txt">
                                       심리적 증상(우울, 불안, 분노 등)
                                    </p>
                                 </div>
                              </label>
                              <label htmlFor="field_02">
                                 <input type="checkbox" className="form-checkbox" id="field_02" />
                                 <div className="checked-item col-group">
                                    <div className="icon">
                                       <i className="xi-check"></i>
                                    </div>
                                    <p className="txt">
                                       성격 및 자기 이해
                                    </p>
                                 </div>
                              </label>
                              <label htmlFor="field_03">
                                 <input type="checkbox" className="form-checkbox" id="field_03" />
                                 <div className="checked-item col-group">
                                    <div className="icon">
                                       <i className="xi-check"></i>
                                    </div>
                                    <p className="txt">
                                       가족 관계
                                    </p>
                                 </div>
                              </label>
                              <label htmlFor="field_04">
                                 <input type="checkbox" className="form-checkbox" id="field_04" />
                                 <div className="checked-item col-group">
                                    <div className="icon">
                                       <i className="xi-check"></i>
                                    </div>
                                    <p className="txt">
                                       대인관계
                                    </p>
                                 </div>
                              </label>
                              <label htmlFor="field_05">
                                 <input type="checkbox" className="form-checkbox" id="field_05" />
                                 <div className="checked-item col-group">
                                    <div className="icon">
                                       <i className="xi-check"></i>
                                    </div>
                                    <p className="txt">
                                       직장 생활
                                    </p>
                                 </div>
                              </label>
                              <label htmlFor="field_06">
                                 <input type="checkbox" className="form-checkbox" id="field_06" />
                                 <div className="checked-item col-group">
                                    <div className="icon">
                                       <i className="xi-check"></i>
                                    </div>
                                    <p className="txt">
                                       학업 진로
                                    </p>
                                 </div>
                              </label>
                              <label htmlFor="field_07">
                                 <input type="checkbox" className="form-checkbox" id="field_07" />
                                 <div className="checked-item col-group">
                                    <div className="icon">
                                       <i className="xi-check"></i>
                                    </div>
                                    <p className="txt">
                                       중독 및 섭식장애
                                    </p>
                                 </div>
                              </label>
                              <label htmlFor="field_08">
                                 <input type="checkbox" className="form-checkbox" id="field_08" />
                                 <div className="checked-item col-group">
                                    <div className="icon">
                                       <i className="xi-check"></i>
                                    </div>
                                    <p className="txt">
                                       정서 및 행동 문제
                                    </p>
                                 </div>
                              </label>
                              <label htmlFor="field_09">
                                 <input type="checkbox" className="form-checkbox" id="field_09" />
                                 <div className="checked-item col-group">
                                    <div className="icon">
                                       <i className="xi-check"></i>
                                    </div>
                                    <p className="txt">
                                       자녀양육
                                    </p>
                                 </div>
                              </label>
                              <label htmlFor="field_10">
                                 <input type="checkbox" className="form-checkbox" id="field_10" />
                                 <div className="checked-item col-group">
                                    <div className="icon">
                                       <i className="xi-check"></i>
                                    </div>
                                    <p className="txt">
                                       또래 관계
                                    </p>
                                 </div>
                              </label>
                              <label htmlFor="field_11">
                                 <input type="checkbox" className="form-checkbox" id="field_11" />
                                 <div className="checked-item col-group">
                                    <div className="icon">
                                       <i className="xi-check"></i>
                                    </div>
                                    <p className="txt">
                                       성문제
                                    </p>
                                 </div>
                              </label>
                              <label htmlFor="field_12">
                                 <input type="checkbox" className="form-checkbox" id="field_12" />
                                 <div className="checked-item col-group">
                                    <div className="icon">
                                       <i className="xi-check"></i>
                                    </div>
                                    <p className="txt">
                                       기타(직접입력)
                                    </p>
                                 </div>
                              </label>
                              <input type="text" className="form-input" />
                           </div>
                        </div>
                     </div>
                     <div className="form-item row-group">
                        <div className="item-default">
                           소개 문구
                           <span className="red">*</span>
                        </div>
                        <div className="item-user">
                           <div className="form-textarea-wrap">
                              <textarea name="" id="" className="form-textarea h200"></textarea>
                              <div className="sticker">
                                 <span>0</span>/500
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="form-item row-group">
                        <div className="item-default">
                           주요 약력
                           <span className="red">*</span>
                           <p className="guide-txt">
                              최대 5개까지 입력 가능하며, 입력할 내용이 없는 경우 '없음'이라고 입력해 주세요.
                           </p>
                        </div>
                        <div className="item-user">
                           <div className="form-input-add-wrap row-group">
                              <div className="form-input-add col-group">
                                 <input type="text" className="form-input" placeholder="약력" />
                                 <button className="btn add-btn">
                                    <i></i>
                                 </button>
                              </div>
                              <div className="form-input-add col-group">
                                 <input type="text" className="form-input" placeholder="약력" />
                                 <button className="btn del-btn">
                                    <i></i>
                                 </button>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div className="mp-section-footer border">
                     <button onClick={() => setCurrentPage(2)} className="form-footer-btn disable"> {/* 필수칸 모두 입력 시 disable 클래스 삭제 */}
                        다음
                     </button>
                  </div>
               </div>
            </div>
         </section>
      </div>
   )
}
const NextPage = () => {

   useEffect(() => {
      window.scrollTo(0, 0);
   }, [])
   return (

      <div className="subpage">
         <section className="user-section">
            <div className="user-wrap">
               <div className="title-wrap row-group">
                  <h2 className="title">
                     상담사 회원가입
                  </h2>
                  <p className="txt">
                     마인드 인사이트 상담사 등록을 위한 기본 정보를 입력해 주세요.
                  </p>
                  <div className="join-tab-list col-group">
                     <div className="join-tab">
                        1. 기본 정보 입력
                     </div>
                     <div className="join-tab active">
                        2. 자격증/학과 입력
                     </div>
                     <div className="join-tab">
                        3. 신청완료
                     </div>
                  </div>
               </div>

               <div className="guide-txt">
                  <span className="red">*</span> 표시는 필수입력 항목입니다.
               </div>

               <div className="form-wrap account-form-wrap row-group">
                  <div className="join-guide-txt-wrap row-group">
                     <p className="join-guide-txt">
                        '관련 자격증(1~4)' 또는 '관련 학과(5~6)' 등록은 <strong className="red">필수</strong>입니다.
                     </p>
                     <p className="join-guide-txt">
                        '1~6' 중 <strong className="red">1개 이상</strong>의 정보를 등록해 주세요.
                     </p>
                     <p className="join-guide-txt">
                        '2~6'의 자격증 사본, 졸업증명서 등은 하단 '증명 서류 등록'에
                        등록해 주세요.(여러장 등록이 가능합니다.)
                     </p>
                  </div>
                  <div className="form-list row-group">
                     <div className="form-item row-group">
                        <div className="item-default">
                           관련 자격증
                           <span className="red">*</span>
                           <p className="guide-txt">
                              중복선택이 가능합니다.
                           </p>
                        </div>
                        <div className="item-user">
                           <div className="form-toggle-wrap active">
                              <div className="form-toggle-btn col-group">
                                 <p className="txt">
                                    1. 한국영성심리분석상담학회 자격증
                                 </p>
                                 <i className="icon"></i>
                              </div>
                              <div className="form-toggle-content">
                                 <div className="form-toggle-table">
                                    <div className="form-toggle-table-head col-group">
                                       <p className="th">
                                          구분
                                       </p>
                                       <p className="th">
                                          취득연월
                                       </p>
                                       <p className="th">
                                          자격증 사본
                                       </p>
                                    </div>
                                    <div className="form-toggle-table-body">
                                       <div className="tr col-group">
                                          <div className="td">
                                             <label htmlFor="cert_01_01">
                                                <input type="checkbox" className="form-checkbox" id="cert_01_01" />
                                                <div className="checked-item col-group">
                                                   <div className="icon">
                                                      <i className="xi-check"></i>
                                                   </div>
                                                   <p className="txt">
                                                      슈퍼바이저
                                                   </p>
                                                </div>
                                             </label>
                                          </div>
                                          <div className="td">
                                             <input type="date" className="form-input" />
                                          </div>
                                          <div className="td">
                                             <label htmlFor="cert_01_01_file" className="btn">
                                                <input type="file" id="cert_01_01_file" />
                                                등록
                                             </label>
                                          </div>
                                          <div className="td">
                                             <div className="file-name col-group">
                                                <p className="txt">
                                                   한국영성심리분석상담학회_슈퍼바이저_홍길동.pdf
                                                </p>
                                                <i className="del-btn"></i>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="tr col-group disable"> {/* 체크박스 선택 시 disable 클래스 삭제 */}
                                          <div className="td">
                                             <label htmlFor="cert_01_02">
                                                <input type="checkbox" className="form-checkbox" id="cert_01_02" />
                                                <div className="checked-item col-group">
                                                   <div className="icon">
                                                      <i className="xi-check"></i>
                                                   </div>
                                                   <p className="txt">
                                                      융합심리분석상담전문가 1급
                                                   </p>
                                                </div>
                                             </label>
                                          </div>
                                          <div className="td">
                                             <input type="date" className="form-input" />
                                          </div>
                                          <div className="td">
                                             <label htmlFor="cert_01_02_file" className="btn">
                                                <input type="file" id="cert_01_02_file" />
                                                등록
                                             </label>
                                          </div>
                                       </div>
                                       <div className="tr col-group disable"> {/* 체크박스 선택 시 disable 클래스 삭제 */}
                                          <div className="td">
                                             <label htmlFor="cert_01_03">
                                                <input type="checkbox" className="form-checkbox" id="cert_01_03" />
                                                <div className="checked-item col-group">
                                                   <div className="icon">
                                                      <i className="xi-check"></i>
                                                   </div>
                                                   <p className="txt">
                                                      융합심리분석상담전문가 2급
                                                   </p>
                                                </div>
                                             </label>
                                          </div>
                                          <div className="td">
                                             <input type="date" className="form-input" />
                                          </div>
                                          <div className="td">
                                             <label htmlFor="cert_01_03_file" className="btn">
                                                <input type="file" id="cert_01_03_file" />
                                                등록
                                             </label>
                                          </div>
                                       </div>
                                       <div className="tr col-group disable"> {/* 체크박스 선택 시 disable 클래스 삭제 */}
                                          <div className="td">
                                             <label htmlFor="cert_01_04">
                                                <input type="checkbox" className="form-checkbox" id="cert_01_04" />
                                                <div className="checked-item col-group">
                                                   <div className="icon">
                                                      <i className="xi-check"></i>
                                                   </div>
                                                   <p className="txt">
                                                      융합심리분석상담전문가 3급
                                                   </p>
                                                </div>
                                             </label>
                                          </div>
                                          <div className="td">
                                             <input type="date" className="form-input" />
                                          </div>
                                          <div className="td">
                                             <label htmlFor="cert_01_04_file" className="btn">
                                                <input type="file" id="cert_01_04_file" />
                                                등록
                                             </label>
                                          </div>
                                          {/* <div className="td">
                                                            <div className="file-name col-group">
                                                                <p className="txt">
                                                                    한국영성심리분석상담학회_슈퍼바이저_홍길동.pdf
                                                                </p>
                                                                <i className="del-btn"></i>
                                                            </div>
                                                        </div> */}
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="form-toggle-wrap active">
                              <div className="form-toggle-btn col-group">
                                 <p className="txt">
                                    2. 상담·임상심리 관련 학회 자격증
                                 </p>
                                 <i className="icon"></i>
                              </div>
                              <div className="form-toggle-content">
                                 <div className="form-label-wrap half col-group">
                                    <label htmlFor="cert_02_01">
                                       <input type="checkbox" className="form-checkbox" id="cert_02_01" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             상담심리사1급
                                          </p>
                                       </div>
                                    </label>
                                    <label htmlFor="cert_02_02">
                                       <input type="checkbox" className="form-checkbox" id="cert_02_02" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             상담심리사2급
                                          </p>
                                       </div>
                                    </label>
                                    <label htmlFor="cert_02_03">
                                       <input type="checkbox" className="form-checkbox" id="cert_02_03" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             1급전문상담사
                                          </p>
                                       </div>
                                    </label>
                                    <label htmlFor="cert_02_04">
                                       <input type="checkbox" className="form-checkbox" id="cert_02_04" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             2급전문상담사
                                          </p>
                                       </div>
                                    </label>
                                    <label htmlFor="cert_02_05">
                                       <input type="checkbox" className="form-checkbox" id="cert_02_05" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             임상심리전문가
                                          </p>
                                       </div>
                                    </label>
                                    <label htmlFor="cert_02_06">
                                       <input type="checkbox" className="form-checkbox" id="cert_02_06" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             산업및조직심리전문가
                                          </p>
                                       </div>
                                    </label>
                                    <label htmlFor="cert_02_07">
                                       <input type="checkbox" className="form-checkbox" id="cert_02_07" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             산업및조직심리사
                                          </p>
                                       </div>
                                    </label>
                                    <label htmlFor="cert_02_08">
                                       <input type="checkbox" className="form-checkbox" id="cert_02_08" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             범죄심리전문가
                                          </p>
                                       </div>
                                    </label>
                                    <label htmlFor="cert_02_09">
                                       <input type="checkbox" className="form-checkbox" id="cert_02_09" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             범죄심리사
                                          </p>
                                       </div>
                                    </label>
                                    <label htmlFor="cert_02_10">
                                       <input type="checkbox" className="form-checkbox" id="cert_02_10" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             발달심리사1급
                                          </p>
                                       </div>
                                    </label>
                                    <label htmlFor="cert_02_11">
                                       <input type="checkbox" className="form-checkbox" id="cert_02_11" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             발달심리사2급
                                          </p>
                                       </div>
                                    </label>
                                    <label htmlFor="cert_02_12">
                                       <input type="checkbox" className="form-checkbox" id="cert_02_12" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             건강심리전문가
                                          </p>
                                       </div>
                                    </label>
                                    <label htmlFor="cert_02_13">
                                       <input type="checkbox" className="form-checkbox" id="cert_02_13" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             중독심리전문가
                                          </p>
                                       </div>
                                    </label>
                                    <label htmlFor="cert_02_14">
                                       <input type="checkbox" className="form-checkbox" id="cert_02_14" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             중독심리사
                                          </p>
                                       </div>
                                    </label>
                                    <label htmlFor="cert_02_15">
                                       <input type="checkbox" className="form-checkbox" id="cert_02_15" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             학교심리사1급
                                          </p>
                                       </div>
                                    </label>
                                    <label htmlFor="cert_02_16">
                                       <input type="checkbox" className="form-checkbox" id="cert_02_16" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             학교심리사2급
                                          </p>
                                       </div>
                                    </label>
                                    <label htmlFor="cert_02_17">
                                       <input type="checkbox" className="form-checkbox" id="cert_02_17" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             코칭심리사1급
                                          </p>
                                       </div>
                                    </label>
                                    <label htmlFor="cert_02_18">
                                       <input type="checkbox" className="form-checkbox" id="cert_02_18" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             코칭심리사2급
                                          </p>
                                       </div>
                                    </label>
                                    <label htmlFor="cert_02_19">
                                       <input type="checkbox" className="form-checkbox" id="cert_02_19" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             코칭심리사3급
                                          </p>
                                       </div>
                                    </label>
                                    <label htmlFor="cert_02_20">
                                       <input type="checkbox" className="form-checkbox" id="cert_02_20" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             인지학습심리사1급
                                          </p>
                                       </div>
                                    </label>
                                    <label htmlFor="cert_02_21">
                                       <input type="checkbox" className="form-checkbox" id="cert_02_21" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             인지학습심리사2급
                                          </p>
                                       </div>
                                    </label>
                                    <label htmlFor="cert_02_22">
                                       <input type="checkbox" className="form-checkbox" id="cert_02_22" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             인지행동지도사1급
                                          </p>
                                       </div>
                                    </label>
                                    <label htmlFor="cert_02_23">
                                       <input type="checkbox" className="form-checkbox" id="cert_02_23" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             인지행동지도사2급
                                          </p>
                                       </div>
                                    </label>
                                    <label htmlFor="cert_02_24">
                                       <input type="checkbox" className="form-checkbox" id="cert_02_24" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             부부가족상담전문가1급
                                          </p>
                                       </div>
                                    </label>
                                    <label htmlFor="cert_02_25">
                                       <input type="checkbox" className="form-checkbox" id="cert_02_25" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             부부가족상담전문가2급
                                          </p>
                                       </div>
                                    </label>
                                    <label htmlFor="cert_02_26">
                                       <input type="checkbox" className="form-checkbox" id="cert_02_26" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             장애인재활상담사1급
                                          </p>
                                       </div>
                                    </label>
                                    <label htmlFor="cert_02_27">
                                       <input type="checkbox" className="form-checkbox" id="cert_02_27" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             장애인재활상담사2급
                                          </p>
                                       </div>
                                    </label>
                                 </div>
                                 <div className="form-item row-group">
                                    <div className="item-default">
                                       증명 서류 등록
                                       <p className="guide-txt">
                                          중복 등록이 가능합니다.
                                       </p>
                                    </div>
                                    <div className="item-user">
                                       <div className="form-input-wrap row-group">
                                          <label htmlFor="cert_02_file" className="form-btn gray">
                                             <input type="file" id="cert_02_file" />
                                             파일등록
                                          </label>
                                          <div className="form-input-wrap row-group">
                                             <div className="file-name col-group">
                                                <p className="txt">
                                                   한국영성심리분석상담학회_슈퍼바이저_홍길동.pdf
                                                </p>
                                                <i className="del-btn"></i>
                                             </div>
                                             <div className="file-name col-group">
                                                <p className="txt">
                                                   한국영성심리분석상담학회_슈퍼바이저_홍길동.pdf
                                                </p>
                                                <i className="del-btn"></i>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="form-toggle-wrap active">
                              <div className="form-toggle-btn col-group">
                                 <p className="txt">
                                    3. 예술 관련 치료사 자격증
                                 </p>
                                 <i className="icon"></i>
                              </div>
                              <div className="form-toggle-content">
                                 <div className="form-label-wrap half col-group">
                                    <label htmlFor="cert_03_01">
                                       <input type="checkbox" className="form-checkbox" id="cert_03_01" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             음악치료사
                                          </p>
                                       </div>
                                    </label>
                                    <label htmlFor="cert_03_02">
                                       <input type="checkbox" className="form-checkbox" id="cert_03_02" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             놀이치료사
                                          </p>
                                       </div>
                                    </label>
                                    <label htmlFor="cert_03_03">
                                       <input type="checkbox" className="form-checkbox" id="cert_03_03" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             미술치료사
                                          </p>
                                       </div>
                                    </label>
                                 </div>
                                 <div className="form-item row-group">
                                    <div className="item-default">
                                       증명 서류 등록
                                       <p className="guide-txt">
                                          중복 등록이 가능합니다.
                                       </p>
                                    </div>
                                    <div className="item-user">
                                       <div className="form-input-wrap row-group">
                                          <label htmlFor="cert_02_file" className="form-btn gray">
                                             <input type="file" id="cert_02_file" />
                                             파일등록
                                          </label>
                                          <div className="form-input-wrap row-group">
                                             <div className="file-name col-group">
                                                <p className="txt">
                                                   한국영성심리분석상담학회_슈퍼바이저_홍길동.pdf
                                                </p>
                                                <i className="del-btn"></i>
                                             </div>
                                             <div className="file-name col-group">
                                                <p className="txt">
                                                   한국영성심리분석상담학회_슈퍼바이저_홍길동.pdf
                                                </p>
                                                <i className="del-btn"></i>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="form-toggle-wrap active">
                              <div className="form-toggle-btn col-group">
                                 <p className="txt">
                                    4. 국가자격증
                                 </p>
                                 <i className="icon"></i>
                              </div>
                              <div className="form-toggle-content">
                                 <div className="form-label-wrap half col-group">
                                    <label htmlFor="cert_04_01">
                                       <input type="checkbox" className="form-checkbox" id="cert_04_01" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             정신건강임상심리사1급
                                          </p>
                                       </div>
                                    </label>
                                    <label htmlFor="cert_04_02">
                                       <input type="checkbox" className="form-checkbox" id="cert_04_02" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             정신건강임상심리사2급
                                          </p>
                                       </div>
                                    </label>
                                    <label htmlFor="cert_04_03">
                                       <input type="checkbox" className="form-checkbox" id="cert_04_03" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             정신건강사회복지사1급
                                          </p>
                                       </div>
                                    </label>
                                    <label htmlFor="cert_04_04">
                                       <input type="checkbox" className="form-checkbox" id="cert_04_04" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             정신건강사회복지사2급
                                          </p>
                                       </div>
                                    </label>
                                    <label htmlFor="cert_04_05">
                                       <input type="checkbox" className="form-checkbox" id="cert_04_05" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             1급언어재활사
                                          </p>
                                       </div>
                                    </label>
                                    <label htmlFor="cert_04_06">
                                       <input type="checkbox" className="form-checkbox" id="cert_04_06" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             2급언어재활사
                                          </p>
                                       </div>
                                    </label>
                                    <label htmlFor="cert_04_07">
                                       <input type="checkbox" className="form-checkbox" id="cert_04_07" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             정신건강간호사 1급
                                          </p>
                                       </div>
                                    </label>
                                    <label htmlFor="cert_04_08">
                                       <input type="checkbox" className="form-checkbox" id="cert_04_08" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             정신건강간호사 2급
                                          </p>
                                       </div>
                                    </label>
                                    <label htmlFor="cert_04_09">
                                       <input type="checkbox" className="form-checkbox" id="cert_04_09" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             정신건강의학과 전문의
                                          </p>
                                       </div>
                                    </label>
                                    <label htmlFor="cert_04_10">
                                       <input type="checkbox" className="form-checkbox" id="cert_04_10" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             신경과 전문의
                                          </p>
                                       </div>
                                    </label>
                                    <label htmlFor="cert_04_11">
                                       <input type="checkbox" className="form-checkbox" id="cert_04_11" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             작업치료사
                                          </p>
                                       </div>
                                    </label>
                                    <label htmlFor="cert_04_12">
                                       <input type="checkbox" className="form-checkbox" id="cert_04_12" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             사회복지사1급
                                          </p>
                                       </div>
                                    </label>
                                    <label htmlFor="cert_04_13">
                                       <input type="checkbox" className="form-checkbox" id="cert_04_13" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             사회복지사2급
                                          </p>
                                       </div>
                                    </label>
                                    <label htmlFor="cert_04_14">
                                       <input type="checkbox" className="form-checkbox" id="cert_04_14" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             사회복지사3급
                                          </p>
                                       </div>
                                    </label>
                                    <label htmlFor="cert_04_15">
                                       <input type="checkbox" className="form-checkbox" id="cert_04_15" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             임상심리사1급
                                          </p>
                                       </div>
                                    </label>
                                    <label htmlFor="cert_04_16">
                                       <input type="checkbox" className="form-checkbox" id="cert_04_16" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             임상심리사2급
                                          </p>
                                       </div>
                                    </label>
                                    <label htmlFor="cert_04_17">
                                       <input type="checkbox" className="form-checkbox" id="cert_04_17" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             직업상담사1급
                                          </p>
                                       </div>
                                    </label>
                                    <label htmlFor="cert_04_18">
                                       <input type="checkbox" className="form-checkbox" id="cert_04_18" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             직업상담사2급
                                          </p>
                                       </div>
                                    </label>
                                    <label htmlFor="cert_04_19">
                                       <input type="checkbox" className="form-checkbox" id="cert_04_19" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             청소년상담사1급
                                          </p>
                                       </div>
                                    </label>
                                    <label htmlFor="cert_04_20">
                                       <input type="checkbox" className="form-checkbox" id="cert_04_20" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             청소년상담사2급
                                          </p>
                                       </div>
                                    </label>
                                    <label htmlFor="cert_04_21">
                                       <input type="checkbox" className="form-checkbox" id="cert_04_21" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             청소년상담사3급
                                          </p>
                                       </div>
                                    </label>
                                    <label htmlFor="cert_04_22">
                                       <input type="checkbox" className="form-checkbox" id="cert_04_22" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             청소년지도사1급
                                          </p>
                                       </div>
                                    </label>
                                    <label htmlFor="cert_04_23">
                                       <input type="checkbox" className="form-checkbox" id="cert_04_23" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             청소년지도사2급
                                          </p>
                                       </div>
                                    </label>
                                    <label htmlFor="cert_04_24">
                                       <input type="checkbox" className="form-checkbox" id="cert_04_24" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             청소년지도사3급
                                          </p>
                                       </div>
                                    </label>
                                    <label htmlFor="cert_04_25">
                                       <input type="checkbox" className="form-checkbox" id="cert_04_25" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             전문상담교사1급
                                          </p>
                                       </div>
                                    </label>
                                    <label htmlFor="cert_04_26">
                                       <input type="checkbox" className="form-checkbox" id="cert_04_26" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             전문상담교사2급
                                          </p>
                                       </div>
                                    </label>
                                 </div>
                                 <div className="form-item row-group">
                                    <div className="item-default">
                                       증명 서류 등록
                                       <p className="guide-txt">
                                          중복 등록이 가능합니다.
                                       </p>
                                    </div>
                                    <div className="item-user">
                                       <div className="form-input-wrap row-group">
                                          <label htmlFor="cert_02_file" className="form-btn gray">
                                             <input type="file" id="cert_02_file" />
                                             파일등록
                                          </label>
                                          <div className="form-input-wrap row-group">
                                             <div className="file-name col-group">
                                                <p className="txt">
                                                   한국영성심리분석상담학회_슈퍼바이저_홍길동.pdf
                                                </p>
                                                <i className="del-btn"></i>
                                             </div>
                                             <div className="file-name col-group">
                                                <p className="txt">
                                                   한국영성심리분석상담학회_슈퍼바이저_홍길동.pdf
                                                </p>
                                                <i className="del-btn"></i>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="form-item row-group">
                        <div className="item-default">
                           관련 학과
                           <span className="red">*</span>
                           <p className="guide-txt">
                              중복선택이 가능합니다.
                           </p>
                        </div>
                        <div className="item-user">
                           <div className="form-toggle-wrap active">
                              <div className="form-toggle-btn col-group">
                                 <p className="txt">
                                    5. 관련 학과 대학교수
                                 </p>
                                 <i className="icon"></i>
                              </div>
                              <div className="form-toggle-content">
                                 <div className="form-label-wrap half col-group">
                                    <label htmlFor="cert_05_01">
                                       <input type="checkbox" className="form-checkbox" id="cert_05_01" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             심리학
                                          </p>
                                       </div>
                                    </label>
                                    <label htmlFor="cert_05_02">
                                       <input type="checkbox" className="form-checkbox" id="cert_05_02" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             교육학
                                          </p>
                                       </div>
                                    </label>
                                    <label htmlFor="cert_05_03">
                                       <input type="checkbox" className="form-checkbox" id="cert_05_03" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             사회복지학
                                          </p>
                                       </div>
                                    </label>
                                    <label htmlFor="cert_05_04">
                                       <input type="checkbox" className="form-checkbox" id="cert_05_04" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             상담학
                                          </p>
                                       </div>
                                    </label>
                                    <label htmlFor="cert_05_05" className="wide">
                                       <input type="checkbox" className="form-checkbox" id="cert_05_05" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             이외 관련학과
                                             <span className="sub-txt">
                                                (특수교육학, 유아교육학, 청소년지도학, 아동학, 작업재활 등)
                                             </span>
                                          </p>
                                       </div>
                                    </label>
                                 </div>
                                 <div className="form-item row-group">
                                    <div className="item-default">
                                       증명 서류 등록
                                       <p className="guide-txt">
                                          중복 등록이 가능합니다.
                                       </p>
                                    </div>
                                    <div className="item-user">
                                       <div className="form-input-wrap row-group">
                                          <label htmlFor="cert_02_file" className="form-btn gray">
                                             <input type="file" id="cert_02_file" />
                                             파일등록
                                          </label>
                                          <div className="form-input-wrap row-group">
                                             <div className="file-name col-group">
                                                <p className="txt">
                                                   한국영성심리분석상담학회_슈퍼바이저_홍길동.pdf
                                                </p>
                                                <i className="del-btn"></i>
                                             </div>
                                             <div className="file-name col-group">
                                                <p className="txt">
                                                   한국영성심리분석상담학회_슈퍼바이저_홍길동.pdf
                                                </p>
                                                <i className="del-btn"></i>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="form-toggle-wrap active">
                              <div className="form-toggle-btn col-group">
                                 <p className="txt">
                                    6. 관련 학과 재학 이상
                                 </p>
                                 <i className="icon"></i>
                              </div>
                              <div className="form-toggle-content">
                                 <div className="form-label-wrap half col-group">
                                    <label htmlFor="cert_06_01">
                                       <input type="checkbox" className="form-checkbox" id="cert_06_01" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             대학원생 (석사)
                                          </p>
                                       </div>
                                    </label>
                                    <label htmlFor="cert_06_02">
                                       <input type="checkbox" className="form-checkbox" id="cert_06_02" />
                                       <div className="checked-item col-group">
                                          <div className="icon">
                                             <i className="xi-check"></i>
                                          </div>
                                          <p className="txt">
                                             대학원생 (박사)
                                          </p>
                                       </div>
                                    </label>
                                 </div>
                                 <div className="form-item row-group">
                                    <div className="item-default">
                                       증명 서류 등록
                                       <p className="guide-txt">
                                          중복 등록이 가능합니다.
                                       </p>
                                    </div>
                                    <div className="item-user">
                                       <div className="form-input-wrap row-group">
                                          <label htmlFor="cert_02_file" className="form-btn gray">
                                             <input type="file" id="cert_02_file" />
                                             파일등록
                                          </label>
                                          <div className="form-input-wrap row-group">
                                             <div className="file-name col-group">
                                                <p className="txt">
                                                   한국영성심리분석상담학회_슈퍼바이저_홍길동.pdf
                                                </p>
                                                <i className="del-btn"></i>
                                             </div>
                                             <div className="file-name col-group">
                                                <p className="txt">
                                                   한국영성심리분석상담학회_슈퍼바이저_홍길동.pdf
                                                </p>
                                                <i className="del-btn"></i>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="form-item row-group">
                        <div className="item-default">
                           이용약관
                        </div>
                        <div className="item-user">
                           <div className="join-agree-wrap row-group">
                              <div className="join-agree-item join-agree-item-all col-group">
                                 <label htmlFor="agree_0">
                                    <input type="checkbox" className="form-checkbox" id="agree_0" />
                                    <div className="checked-item col-group">
                                       <div className="icon">
                                          <i className="xi-check"></i>
                                       </div>
                                       <p className="txt">
                                          모두 동의합니다.
                                       </p>
                                    </div>
                                 </label>
                              </div>
                              <div className="join-agree-item col-group">
                                 <label htmlFor="agree_1">
                                    <input type="checkbox" className="form-checkbox" id="agree_1" />
                                    <div className="checked-item col-group">
                                       <div className="icon">
                                          <i className="xi-check"></i>
                                       </div>
                                       <p className="txt">
                                          [필수] 서비스이용약관에 동의합니다.
                                       </p>
                                    </div>
                                 </label>
                                 <a href="privacy_policy.html" target="_blank" className="more-btn">
                                    보기
                                 </a>
                              </div>
                              <div className="join-agree-item col-group">
                                 <label htmlFor="agree_2">
                                    <input type="checkbox" className="form-checkbox" id="agree_2" />
                                    <div className="checked-item col-group">
                                       <div className="icon">
                                          <i className="xi-check"></i>
                                       </div>
                                       <p className="txt">
                                          [필수] 개인정보처리방침 약관에 동의합니다.
                                       </p>
                                    </div>
                                 </label>
                                 <a href="privacy_policy.html" target="_blank" className="more-btn">
                                    보기
                                 </a>
                              </div>
                              <div className="join-agree-item col-group">
                                 <label htmlFor="agree_3">
                                    <input type="checkbox" className="form-checkbox" id="agree_3" />
                                    <div className="checked-item col-group">
                                       <div className="icon">
                                          <i className="xi-check"></i>
                                       </div>
                                       <p className="txt">
                                          [선택] 마케팅 정보 수신에 동의합니다.
                                       </p>
                                    </div>
                                 </label>
                                 <a href="privacy_policy.html" target="_blank" className="more-btn">
                                    보기
                                 </a>
                              </div>
                              <div className="checked-item-sub col-group">
                                 <label htmlFor="agree_4">
                                    <input type="checkbox" className="form-checkbox" id="agree_4" />
                                    <div className="checked-item col-group">
                                       <div className="icon">
                                          <i className="xi-check"></i>
                                       </div>
                                       <p className="txt">
                                          SMS/알림톡
                                       </p>
                                    </div>
                                 </label>
                                 <label htmlFor="agree_5">
                                    <input type="checkbox" className="form-checkbox" id="agree_5" />
                                    <div className="checked-item col-group">
                                       <div className="icon">
                                          <i className="xi-check"></i>
                                       </div>
                                       <p className="txt">
                                          이메일
                                       </p>
                                    </div>
                                 </label>
                                 <label htmlFor="agree_6">
                                    <input type="checkbox" className="form-checkbox" id="agree_6" />
                                    <div className="checked-item col-group">
                                       <div className="icon">
                                          <i className="xi-check"></i>
                                       </div>
                                       <p className="txt">
                                          전화
                                       </p>
                                    </div>
                                 </label>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div className="mp-section-footer border">
                     <Link to="/join/counselor/done" className="form-footer-btn disable"> {/* 필수칸 모두 입력 시 disable 클래스 삭제 */}
                        상담사 등록 신청
                     </Link>
                  </div>
               </div>
            </div>


         </section>

      </div>
   )
}

const CounselorForm = ({ isSocial }) => {

   const [currentPage, setCurrentPage] = useState(1);
   if (currentPage === 1) {
      return <IndexPage isSocial={isSocial} setCurrentPage={setCurrentPage} />
   } else if (currentPage === 2) {
      return <NextPage />
   }

}


export default CounselorForm