import React from 'react'
import { Outlet } from 'react-router-dom'

// Components
import SideGnb from '../../../components/user/my-page/SideGnb'

const MyPagelayout = () => {
   return (
      <div className="subpage">

         <section className="myPage-section mypage_code">

            <div className="stateBox">
               <div className="container">
                  <div className="le-wrap col-group">
                     <i className="icon"></i>
                     <p className="title">마이페이지</p>
                     <p className="sub">
                        안녕하세요,
                        <span className="txt-bold">홍길동 님!</span>
                     </p>
                  </div>
               </div>
            </div>
            <div className="container inner-container">
               <SideGnb />
               <Outlet />
            </div>
         </section>

      </div>
   )
}

export default MyPagelayout