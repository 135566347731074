import React from 'react'
import { Link } from 'react-router-dom'
import { images } from '../../../assets/images'

const Order = () => {
   return (
      <div className="subpage order">
         <div className="routeBox">
            <div className="container">
               <div className="le-wrap">
                  <i className="icon"></i>
                  <p className="title">주문/결제</p>
               </div>
               <div className="ri-wrap">
                  <p className="route-title">장바구니</p>
                  <i className="route-icon"></i>
                  <p className="route-title now">주문/결제</p>
                  <i className="route-icon"></i>
                  <p className="route-title">완료</p>
               </div>
            </div>
         </div>
         <div className="container">
            <div className="order-container col-group">
               <div className="order-wrap row-group">
                  <div className="order-section">
                     <div className="order-sec-title-wrap col-group">
                        <h2 className="order-sec-title">
                           상품정보
                        </h2>
                     </div>
                     <div className="order-detail-list row-group">
                        <div className="order-detail-item">
                           <div className="prod-item col-group">
                              <div className="img-box">
                                 <img src="images/mind_insight_thumbnail_02.png" alt="" />
                              </div>
                              <div className="txt-wrap row-group">
                                 <div className="title-group row-group">
                                    <p className="eng-title">
                                       Mind Insight
                                    </p>
                                    <p className="title">
                                       마인드 인사이트
                                    </p>
                                    <p className="txt">
                                       마인드 인사이트는 심리검사 상품으로 구매후 직접 사용하거나 마이페이지에서 선물하기가 가능합니다. <br />
                                       자세한 내용은 <Link to="sub02_mindInsight_03.html#anchor_2" className="link" target="_blank">자주하는 질문</Link>을 참고해주세요.
                                    </p>
                                 </div>
                                 <div className="prod-price-wrap col-group">
                                    <p className="title">
                                       기본가
                                    </p>
                                    <div className="prod-price-group col-group">
                                       <p className="percent">50%</p> {/* 할인 퍼센트 */}
                                       <p className="price">
                                          <strong>50,000</strong>원
                                       </p> {/* 할인된 가격 */}
                                       <p className="before">100,000원</p> {/* 기존가격 */}
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="order-detail-item">
                           <div className="prod-option-wrap col-group">
                              <div className="prod-option-group row-group">
                                 <p className="title">
                                    마인드 인사이트 (+ 0원)
                                    {/* 마인드 테라피에서만 보여지는 텍스트
                                       <span className="red"> 
                                           2024.12.19. 00:00 이후 환불불가
                                       </span>
                                        */}
                                 </p>
                                 <p className="txt">
                                    수량 : 2
                                 </p>
                              </div>
                              <p className="price">
                                 <strong>100,000</strong>원
                              </p>
                           </div>
                        </div>
                        <div className="order-detail-item">
                           <div className="prod-option-wrap col-group">
                              <div className="prod-option-group row-group">
                                 <p className="title">
                                    마인드 인사이트 (+ 0원)
                                    {/* 마인드 테라피에서만 보여지는 텍스트
                                       <span className="red"> 
                                           2024.12.19. 00:00 이후 환불불가
                                       </span>
                                        */}
                                 </p>
                                 <p className="txt">
                                    수량 : 2
                                 </p>
                              </div>
                              <p className="price">
                                 <strong>100,000</strong>원
                              </p>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="order-section">
                     <div className="order-sec-title-wrap">
                        <h2 className="order-sec-title">
                           결제 방법
                        </h2>
                     </div>
                     <div className="payment-method-group col-group">
                        <label for="payment_1" className="payment-method">
                           <input type="radio" id="payment_1" name="payment_type" className="form-checkbox" />
                           <div className="payment-method-item col-group">
                              <p className="payment-method-title">신용/체크카드</p>
                           </div>
                        </label>
                        <label for="payment_2" className="payment-method">
                           <input type="radio" id="payment_2" name="payment_type" className="form-checkbox" />
                           <div className="col-group"></div>
                           <div className="payment-method-item col-group">
                              <img src={images.icon_naver_pay} alt="" className="img" />
                              <p className="payment-method-title">네이버페이</p>
                           </div>
                        </label>
                        <label for="payment_3" className="payment-method">
                           <input type="radio" id="payment_3" name="payment_type" className="form-checkbox" />
                           <div className="payment-method-item col-group">
                              <img src={images.icon_kakao_pay} alt="" className="img" />
                              <p className="payment-method-title">카카오페이</p>
                           </div>
                        </label>
                     </div>
                  </div>
               </div>
               <div className="order-sticky-wrap">
                  <h2 className="order-sec-title">
                     결제정보
                  </h2>
                  <div className="order-price-wrap row-group">
                     <div className="order-price-group row-group">
                        <div className="order-price-item col-group">
                           <p className="item-default">
                              <strong>상품합계</strong>
                           </p>
                           <p className="item-user">
                              440,000 원
                           </p>
                        </div>
                     </div>
                     <div className="order-price-group row-group">
                        <div className="order-price-item col-group">
                           <p className="item-default">
                              상품할인
                           </p>
                           <p className="item-user">
                              <span className="blue">
                                 - 200,000 원
                              </span>
                           </p>
                        </div>
                     </div>
                     <div className="order-price-group order-total-price-group row-group">
                        <div className="order-price-item col-group">
                           <p className="item-default">
                              최종 결제금액
                           </p>
                           <p className="item-user">
                              <strong className="red">102,900</strong> 원
                           </p>
                        </div>
                     </div>
                  </div>

                  <div className="policy-agree-wrap row-group">
                     <label for="agree_all">
                        <input type="checkbox" className="form-checkbox" id="agree_all" />
                        <div className="checked-item checked-all-item col-group">
                           <div className="icon">
                              <i className="xi-check"></i>
                           </div>
                           <p className="txt">
                              전체 동의하기
                           </p>
                        </div>
                     </label>
                     <label for="agree_1">
                        <input type="checkbox" className="form-checkbox" id="agree_1" />
                        <div className="checked-item col-group">
                           <div className="icon">
                              <i className="xi-check"></i>
                           </div>
                           <div className="txt col-group">
                              [필수] 개인정보 수집 및 이용 동의
                              <Link to="" className="link">상세보기</Link>
                           </div>
                        </div>
                     </label>
                     <label for="agree_2">
                        <input type="checkbox" className="form-checkbox" id="agree_2" />
                        <div className="checked-item col-group">
                           <div className="icon">
                              <i className="xi-check"></i>
                           </div>
                           <div className="txt col-group">
                              [필수] 개인정보 제 3자 제공 동의
                              <Link to="" className="link">상세보기</Link>
                           </div>
                        </div>
                     </label>
                     <label for="agree_3">
                        <input type="checkbox" className="form-checkbox" id="agree_3" />
                        <div className="checked-item col-group">
                           <div className="icon">
                              <i className="xi-check"></i>
                           </div>
                           <div className="txt col-group">
                              [필수] 전자결제대행 이용 동의
                              <Link to="" className="link">상세보기</Link>
                           </div>
                        </div>
                     </label>
                  </div>

                  <Link to="/order/done" className="submit-btn">
                     결제하기
                  </Link>
               </div>
               <div className="test-guide-txt-wrap">
                  <p className="title">
                     마인드 아이티 상품 환불 정책
                  </p>
                  <div className="test-guide-txt-group row-group">
                     <div className="test-guide-txt row-group">
                        <p className="default">
                           1. 마인드 인사이트
                        </p>
                        <p className="user">
                           마인드 인사이트는 구매 후 4주 동안 유효합니다. 유효기간 동안 진행하지 않았다면 결제한 수단으로 환불 가능합니다. <br />
                           마인드 인사이트를 시작했거나 완료한 경우에는 환불되지 않습니다.
                        </p>
                     </div>
                     <div className="test-guide-txt row-group">
                        <p className="default">
                           2. 마인드 리포트
                        </p>
                        <p className="user">
                           주문 제작 상품으로 환불 및 반품이 불가합니다.
                        </p>
                     </div>
                     <div className="test-guide-txt row-group">
                        <p className="default">
                           3. 해석상담
                        </p>
                        <p className="user">
                           아래와 같은 경우 환불이 불가합니다. <br />
                           1&#41; 상담이 시작된 경우 <br />
                           2&#41; 상담 당일에 사전 협의 없이 참석하지 않은 경우
                        </p>
                     </div>
                     <div className="test-guide-txt row-group">
                        <p className="default">
                           4. 마인드 테라피
                        </p>
                        <p className="user">
                           1&#41; 10일전 100% 환불 <br />
                           2&#41; 7일전 50% 환불 <br />
                           3&#41; 3일전부터 환불불가
                        </p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}

export default Order