import React from 'react'
import { createBrowserRouter, Navigate } from 'react-router-dom'
import Root from './Root'

// Pages
// not found
import NotFound from './components/NotFound'
// main
import MainPage from './pages/MainPage'
// company
import About from './pages/company/About'
// mind insight
import Introduction from './pages/mind-insight/Introduction'
import MindInsight from './pages/mind-insight/MindInsight'
import Consultation from './pages/mind-insight/Consultation'
import MindReport from './pages/mind-insight/MindReport'
// mind therapy
import MeditationReflection from './pages/mind-therapy/MeditationReflection'
import DailyLove from './pages/mind-therapy/DailyLove'
import Confession from './pages/mind-therapy/Confession'
import Eap from './pages/mind-therapy/Eap'
import WalkingMeditation from './pages/mind-therapy/WalkingMeditation'
// sapienza-academia
import ProgramOverview from './pages/sapienza-academia/ProgramOverview'
import Level1Course from './pages/sapienza-academia/Level1Course'
import Level2Course from './pages/sapienza-academia/Level2Course'
import Level3Course from './pages/sapienza-academia/Level3Course'
import Supervisor from './pages/sapienza-academia/Supervisor'
// counseloer center
import Counselor from './pages/counselor-center/Counselor'
import Center from './pages/counselor-center/Center'
// user
import LoginSelect from './pages/user/login/LoginSelect'
import LoginIndividual from './pages/user/login/LoginIndividual'
import LoginCounselor from './pages/user/login/LoginCounselor'
import JoinSelect from './pages/user/join/JoinSelect'
import JoinIndividual from './pages/user/join/individual/JoinIndividual'
import JoinCounselor from './pages/user/join/counselor/JoinCounselor'
import IndividualEmail from './pages/user/join/individual/IndividualEmail'
import CounselorEmail from './pages/user/join/counselor/CounselorEmail'
import Done from './pages/user/join/Done'
import TestCodeUseOrGift from './pages/user/my-page/TestCodeUseOrGift'
import TestResult from './pages/user/my-page/testResult/Result'
import TestProgress from './pages/user/my-page/testResult/Progress'
import TestCodeSend from './pages/user/my-page/TestCodeSend'
import SendHistory from './pages/user/my-page/SendHistory'
import PurchaseHistory from './pages/user/my-page/purchase/PurchaseHistory'
import RefundHistory from './pages/user/my-page/purchase/RefundHistory'
import Inquiry from './pages/user/my-page/inquiry/Inquiry'
import UseOrGiftHistory from './pages/user/my-page/UseOrGiftHistory'
import AccountManagement from './pages/user/my-page/AccountManagement'
import MyPagelayout from './pages/user/my-page/Layout'
import InquiryDetail from './pages/user/my-page/inquiry/Detail'
import InquiryCreate from './pages/user/my-page/inquiry/Create'
import CounselorDone from './pages/user/join/counselor/Done'
import PurchaseDetailPage from './pages/user/my-page/purchase/DetailPage'
import Order from './pages/user/order/Order'
import OrderDone from './pages/user/order/Done'
import FindId from './pages/user/find-account/id/FindId'
import FindPw from './pages/user/find-account/pw/FindPw'
import IndividualSocial from './pages/user/join/individual/Social'
import FindIdDone from './pages/user/find-account/id/FindIdDone'
import ChangePw from './pages/user/find-account/pw/ChangePw'
import CounselorSocial from './pages/user/join/counselor/Social'

const Router = createBrowserRouter([
   {
      path: '/',
      element: <Root />,
      children: [
         {
            path: '',
            element: <MainPage />
         },
         {
            path: 'company',
            children: [
               {
                  path: '',
                  element: <Navigate to="about" />
               },
               {
                  path: 'about',
                  element: <About />
               }
            ]
         },
         {
            path: 'mind-insight',
            children: [
               {
                  path: '',
                  element: <Navigate to="introduction" />
               },
               {
                  path: 'introduction',
                  element: <Introduction />
               },
               {
                  path: 'mind-insight',
                  element: <MindInsight />
               },
               {
                  path: 'mind-report',
                  element: <MindReport />
               },
               {
                  path: 'consultation',
                  element: <Consultation />
               }
            ]
         },
         {
            path: 'mind-therapy',
            children: [
               {
                  path: '',
                  element: <Navigate to="walking-meditation" />
               },
               {
                  path: 'walking-meditation',
                  element: <WalkingMeditation />,
               },
               {
                  path: 'meditation-reflection',
                  element: <MeditationReflection />,
               },
               {
                  path: 'daily-love',
                  element: <DailyLove />,
               },
               {
                  path: 'confession',
                  element: <Confession />,
               },
               {
                  path: 'eap',
                  element: <Eap />,
               },
            ]
         },
         {
            path: 'sapienza-academia',
            children: [
               {
                  path: '',
                  element: <Navigate to="program-overview" />
               },
               {
                  path: 'program-overview',
                  element: <ProgramOverview />
               },
               {
                  path: 'level-1-course',
                  element: <Level1Course />
               },
               {
                  path: 'level-2-course',
                  element: <Level2Course />
               },
               {
                  path: 'level-3-course',
                  element: <Level3Course />
               },
               {
                  path: 'supervisor',
                  element: <Supervisor />
               },
            ]
         },
         {
            path: 'counselor-center',
            children: [
               {
                  path: '',
                  element: <Navigate to="counselor" />
               },
               {
                  path: 'counselor',
                  element: <Counselor />
               },
               {
                  path: 'center',
                  element: <Center />
               }
            ]
         },
         {
            path: 'login',
            children: [
               {
                  path: 'select',
                  element: <LoginSelect />
               },
               {
                  path: '',
                  element: <LoginIndividual />
               },
               {
                  path: 'counselor',
                  element: <LoginCounselor />
               },
            ]
         },
         {
            path: 'join',
            children: [
               // 일반, 상담사 선택(공통 사용)
               {
                  path: 'select',
                  element: <JoinSelect />
               },
               // 이메일, 소셜 선택
               {
                  path: '',
                  element: <JoinIndividual />
               },
               {
                  path: 'email',
                  element: <IndividualEmail />
               },
               {
                  path: 'social',
                  element: <IndividualSocial />
               },
               {
                  path: 'done',
                  element: <Done />
               },
               {
                  path: 'counselor',
                  children: [
                     // 이메일, 소셜 선택
                     {
                        path: '',
                        element: <JoinCounselor />
                     },
                     {
                        path: 'email',
                        element: <CounselorEmail />
                     },
                     {
                        path: 'social',
                        element: <CounselorSocial />,
                     },
                     {
                        path: 'done',
                        element: <CounselorDone />
                     }
                  ]
               },
            ]
         },
         {
            path: 'my-page',
            element: <MyPagelayout />,
            children: [
               {
                  path: 'use-gift-history',
                  element: <UseOrGiftHistory />
               },
               {
                  path: 'test',
                  children: [
                     {
                        path: 'progress',
                        element: <TestProgress />,
                     },
                     {
                        path: 'result',
                        element: <TestResult />,
                     },
                     {
                        path: 'code-send',
                        element: <TestCodeSend />,
                     },
                     {
                        path: 'code-use-gift',
                        element: <TestCodeUseOrGift />,
                     },
                  ]
               },
               {
                  path: 'send-history',
                  element: <SendHistory />
               },
               {
                  path: 'purchase',
                  children: [
                     {
                        path: '',
                        element: <Navigate to="history" />
                     },
                     {
                        path: 'history',
                        element: <PurchaseHistory />
                     },
                     {
                        path: 'detail',
                        element: <PurchaseDetailPage />
                     },
                  ]
               },
               {
                  path: 'refund',
                  children: [
                     {
                        path: '',
                        element: <Navigate to='history' />
                     },
                     {
                        path: 'history',
                        element: <RefundHistory />
                     },
                     {
                        path: 'detail',
                        element: <PurchaseDetailPage />
                     }
                  ]
               },
               {
                  path: 'inquiry',
                  children: [
                     {
                        path: '',
                        element: <Inquiry />
                     },
                     {
                        path: ':id',
                        element: <InquiryDetail />
                     },
                     {
                        path: 'create',
                        element: <InquiryCreate />
                     }
                  ]
               },
               {
                  path: 'account-management',
                  element: <AccountManagement />
               },
            ]
         },
         {
            path: 'find-account',
            children: [
               {
                  path: '',
                  element: <Navigate to='id' />
               },
               {
                  path: 'id',
                  element: <FindId />
               },
               {
                  path: 'id-done',
                  element: <FindIdDone />
               },
               {
                  path: 'pw',
                  element: <FindPw />
               },
               {
                  path: 'change-pw',
                  element: <ChangePw />
               }
            ]
         },
         {
            path: 'order',
            children: [
               {
                  path: '',
                  element: <Order />
               },
               {
                  path: 'done',
                  element: <OrderDone />
               }
            ]
         },
      ],
      errorElement: <NotFound />
   }
])

export default Router