import React from 'react'
import { Link } from 'react-router-dom'

const FindId = () => {
   return (
      <div className="subpage">
         <section className="user-section">
            <div className="user-wrap">
               <div className="title-wrap row-group">
                  <h2 className="title">
                     아이디 찾기
                  </h2>
                  <p className="txt">
                     가입시 등록하신 휴대폰 번호를 입력해주세요.
                  </p>
               </div>
               <div className="form-wrap account-form-wrap row-group">
                  <div className="form-item row-group">
                     <div className="item-default">
                        휴대폰 번호
                        <span className="red">*</span>
                     </div>
                     <div className="item-user">
                        <div className="form-input-wrap row-group">
                           <div className="form-btn-wrap col-group">
                              <input type="number" className="form-input" placeholder="휴대폰 번호" />
                              <button className="form-btn disable">인증번호 받기</button> {/* 휴대폰 번호 입력 시 disable 클래스 삭제 */}
                           </div>
                           <div className="form-btn-wrap col-group">
                              <input type="number" className="form-input" placeholder="휴대폰 번호" value="01012341234" />
                              <button className="form-btn black">인증번호 재발송</button> {/* 인증번호 받기 버튼 누른 후 */}
                           </div>
                           <div className="form-btn-wrap col-group">
                              <input type="number" className="form-input" placeholder="휴대폰 번호" value="01012341234" />
                              <button className="form-btn gray">인증완료</button> {/* 인증완료 후 */}
                           </div>
                           {/* 인증번호 받기 버튼 클릭 시 활성화 / 인증 완료 후 숨김 */}
                           <div className="form-btn-wrap col-group">
                              <input type="number" className="form-input" placeholder="인증번호" />
                              <button className="form-btn disable">인증확인</button> {/* 인증번호 입력 시 disable 클래스 삭제 */}
                           </div>
                           {/* //인증번호 받기 버튼 클릭 시 활성화 / 인증 완료 후 숨김 */}
                           <p className="guide-txt red">
                              휴대폰으로 발송된 인증번호를 입력해주세요.
                           </p>
                        </div>
                     </div>
                  </div>
                  <div className="mp-section-footer border">
                     <Link to="/find-account/id-done" className="form-footer-btn disable"> {/* 필수칸 모두 입력 시 disable 클래스 삭제 */}
                        다음
                     </Link>
                  </div>
               </div>
            </div>
         </section>
      </div>
   )
}

export default FindId