import { useEffect, useState } from "react";

const FixCart = () => {

   const [fixedItemisOn, setFixedItemIsOn] = useState(false);
   useEffect(() => {
      const handleScroll = () => {
         const courseContentWrap = document.querySelector('.course-content-wrap');
         const courseContentWrapTop = courseContentWrap?.getBoundingClientRect().top + window.scrollY;

         // 스크롤 위치가 요소의 높이 이상일 경우 fixedItemisOn(true)
         if (window.scrollY >= courseContentWrapTop) {
            setFixedItemIsOn(true);
         } else {
            setFixedItemIsOn(false);
         }
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
         window.removeEventListener('scroll', handleScroll);
      };
   }, []);
   
   return fixedItemisOn;
}

export default FixCart;