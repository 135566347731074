import React from 'react'

const ChangePw = () => {
   return (
      <div class="subpage">
         <section class="user-section">
            <div class="user-wrap">
               <div class="title-wrap row-group">
                  <h2 class="title">
                     비밀번호 변경
                  </h2>
                  <p class="txt">
                     새로운 비밀번호를 등록해 주세요
                  </p>
               </div>
               <div class="form-wrap account-form-wrap row-group">
                  <div class="form-item">
                     <div class="item-user">
                        <div class="form-input-wrap row-group">
                           <input type="password" class="form-input" placeholder="비밀번호" />
                           <input type="password" class="form-input" placeholder="비밀번호 재확인" />
                        </div>
                     </div>
                  </div>
                  <div class="mp-section-footer border">
                     <a href="pw_change_done.html" class="form-footer-btn disable"> {/* 필수칸 모두 입력 시 disable 클래스 삭제 */}
                        비밀번호 변경
                     </a>
                  </div>
               </div>
            </div>
         </section>
      </div>
   )
}

export default ChangePw