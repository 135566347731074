import React from 'react'

const FindPw = () => {
   return (
      <div class="subpage">
         <section class="user-section">
            <div class="user-wrap">
               <div class="title-wrap row-group">
                  <h2 class="title">
                     비밀번호 찾기
                  </h2>
                  <p class="txt">
                     비밀번호를 찾으려는 아이디와 휴대폰 번호를 입력해주세요.
                  </p>
               </div>
               <div class="form-wrap account-form-wrap row-group">
                  <div class="form-list row-group">
                     <div class="form-item row-group">
                        <div class="item-default">
                           이메일
                           <span class="red">*</span>
                        </div>
                        <div class="item-user">
                           <input type="text" class="form-input" placeholder="이메일(아이디)" />
                        </div>
                     </div>
                     <div class="form-item row-group">
                        <div class="item-default">
                           휴대폰 번호
                           <span class="red">*</span>
                        </div>
                        <div class="item-user">
                           <div class="form-input-wrap row-group">
                              <div class="form-btn-wrap col-group">
                                 <input type="number" class="form-input" placeholder="휴대폰 번호" />
                                 <button class="form-btn disable">인증번호 받기</button> {/* 휴대폰 번호 입력 시 disable 클래스 삭제 */}
                              </div>
                              <div class="form-btn-wrap col-group">
                                 <input type="number" class="form-input" placeholder="휴대폰 번호" value="01012341234" />
                                 <button class="form-btn black">인증번호 재발송</button> {/* 인증번호 받기 버튼 누른 후 */}
                              </div>
                              <div class="form-btn-wrap col-group">
                                 <input type="number" class="form-input" placeholder="휴대폰 번호" value="01012341234" />
                                 <button class="form-btn gray">인증완료</button> {/* 인증완료 후 */}
                              </div>
                              {/* 인증번호 받기 버튼 클릭 시 활성화 / 인증 완료 후 숨김 */}
                              <div class="form-btn-wrap col-group">
                                 <input type="number" class="form-input" placeholder="인증번호" />
                                 <button class="form-btn disable">인증확인</button> {/* 인증번호 입력 시 disable 클래스 삭제 */}
                              </div>
                              {/* //인증번호 받기 버튼 클릭 시 활성화 / 인증 완료 후 숨김 */}
                              <p class="guide-txt red">
                                 휴대폰으로 발송된 인증번호를 입력해주세요.
                              </p>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="mp-section-footer border">
                     <a href="find_pw_change.html" class="form-footer-btn disable"> {/* 필수칸 모두 입력 시 disable 클래스 삭제 */}
                        비밀번호 변경
                     </a>
                  </div>
               </div>
            </div>
         </section>
      </div>
   )
}

export default FindPw