import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

const Header = () => {

   const pathname = useLocation().pathname;

   useEffect(() => {
      window.scrollTo(0, 0);

   }, [pathname]);

   const [headerIndex, setHeaderIndex] = useState(false);
   
   const sitemapHandle = () => {
      setHeaderIndex(prev => !prev)
   }

   return (
      <header id="header">
         <div className="header-wrap col-group">
            <h1 className="logo">
               <Link to="/"></Link>
            </h1>
            <div className={`gnb col-group ${headerIndex ? "sitemap" : ""}`}>
               <div className="gnb-item">
                  <p className="gnb-menu">
                     회사소개
                     <span className="icon mb"></span>
                  </p>
                  <div className="sub-gnb-wrap">
                     <div className="sub-gnb row-group">
                        <Link className="sub-gnb-item" to="/company/about">
                           회사소개
                        </Link>
                     </div>
                  </div>
               </div>
               <div className="gnb-item">
                  <p className="gnb-menu">
                     마인드 인사이트
                     <span className="icon mb"></span>
                  </p>
                  <div className="sub-gnb-wrap">
                     <div className="sub-gnb row-group">
                        <Link className="sub-gnb-item" to="/mind-insight/introduction">
                           소개
                        </Link>
                        <Link className="sub-gnb-item" to="/mind-insight/mind-insight">
                           마인드 인사이트
                        </Link>
                        <Link className="sub-gnb-item" to="/mind-insight/mind-report">
                           마인드 리포트 구매
                        </Link>
                        <Link className="sub-gnb-item" to="/mind-insight/consultation">
                           해석상담 구매
                        </Link>
                     </div>
                  </div>
               </div>
               <div className="gnb-item">
                  <p className="gnb-menu">
                     마인드 테라피
                     <span className="icon mb"></span>
                  </p>
                  <div className="sub-gnb-wrap">
                     <div className="sub-gnb row-group">
                        <Link className="sub-gnb-item" to="/mind-therapy/walking-meditation">
                           걷기 명상
                        </Link>
                        <Link className="sub-gnb-item" to="/mind-therapy/meditation-reflection">
                           명상과 관상
                        </Link>
                        <Link className="sub-gnb-item" to="/mind-therapy/daily-love">
                           하루愛
                        </Link>
                        <Link className="sub-gnb-item" to="/mind-therapy/confession">
                           고백해
                        </Link>
                        <Link className="sub-gnb-item" to="/mind-therapy/eap">
                           EAP
                        </Link>
                     </div>
                  </div>
               </div>
               <div className="gnb-item">
                  <p className="gnb-menu">
                     사피엔자 아카데미아
                     <span className="icon mb"></span>
                  </p>
                  <div className="sub-gnb-wrap">
                     <div className="sub-gnb row-group">
                        <Link className="sub-gnb-item" to="/sapienza-academia/program-overview">
                           교육소개
                        </Link>
                        <Link className="sub-gnb-item" to="/sapienza-academia/level-3-course">
                           3급 과정
                        </Link>
                        <Link className="sub-gnb-item" to="/sapienza-academia/level-2-course">
                           2급 과정
                        </Link>
                        <Link className="sub-gnb-item" to="/sapienza-academia/level-1-course">
                           1급 과정
                        </Link>
                        <Link className="sub-gnb-item" to="/sapienza-academia/supervisor">
                           슈퍼바이저
                        </Link>
                     </div>
                  </div>
               </div>
               <div className="gnb-item">
                  <p className="gnb-menu">
                     상담사 · 센터소개
                     <span className="icon mb"></span>
                  </p>
                  <div className="sub-gnb-wrap">
                     <div className="sub-gnb row-group">
                        <Link className="sub-gnb-item" to="/counselor-center/counselor">
                           상담사
                        </Link>
                        <Link className="sub-gnb-item" to="/counselor-center/center">
                           상담·코칭센터
                        </Link>
                     </div>
                  </div>
               </div>
            </div>

            <div className="btn-wrap col-group">
               <button className="inquiry-btn col-group" onClick={() => document.querySelector('.inquiry-modal').style.display = 'block'}>
                  <i></i>
                  상담사 등록
               </button>
               <Link to="/login/select" className="user-btn"> {/* 로그인 전에 보이는 버튼 */}
                  <i></i>
               </Link>
               <Link to="/my-page/test/code-use-gift" className="user-btn pc"> {/* PC 로그인 후에 보이는 버튼*/}
                  <i></i>
               </Link>
               <Link to="mypage_index.html" className="user-btn mb"> {/* MB 로그인 후에 보이는 버튼*/}
                  <i></i>
               </Link>
            </div>

            <div
               onClick={() => sitemapHandle()}
               className={`sitemap-btn ${headerIndex ? "active" : ""}`}
            >
               <span></span>
               <span></span>
               <span></span>
            </div>

            <div className={`sitemap-bg ${headerIndex ? "active" : ""}`}></div>
         </div>
         <div className={`mb-gnb ${headerIndex ? "active" : ""}`}>
            <div className="mb-gnb-container">
               <div className="gnb-home">
                  <img src="/images/logo.png" alt="" className="gnb-home-logo" />
               </div>
               <div className="mb-gnb-wrap">
                  <div className="gnb-item">
                     <p className="gnb-menu">
                        회사소개
                        <i className="icon"></i>
                     </p>
                     <div className="sub-gnb-wrap">
                        <div className="sub-gnb row-group">
                           <Link className="sub-gnb-item" to="sub01_about.html">
                              회사소개
                           </Link>
                        </div>
                     </div>
                  </div>
                  <div className="gnb-item">
                     <p className="gnb-menu">
                        마인드 인사이트
                        <i className="icon"></i>
                     </p>
                     <div className="sub-gnb-wrap">
                        <div className="sub-gnb row-group">
                           <Link className="sub-gnb-item" to="sub02_mindInsight_01.html">
                              소개
                           </Link>
                           <Link className="sub-gnb-item" to="sub02_mindInsight_02.html">
                              마인드 인사이트
                           </Link>
                           <Link className="sub-gnb-item" to="sub02_mindInsight_03.html">
                              마인드 리포트 구매
                           </Link>
                           <Link className="sub-gnb-item" to="sub02_mindInsight_04.html">
                              해석상담 구매
                           </Link>
                        </div>
                     </div>
                  </div>
                  <div className="gnb-item">
                     <p className="gnb-menu">
                        마인드 테라피
                        <i className="icon"></i>
                     </p>
                     <div className="sub-gnb-wrap">
                        <div className="sub-gnb row-group">
                           <Link className="sub-gnb-item" to="sub03_mindTherapy_01.html">
                              걷기 명상
                           </Link>
                           <Link className="sub-gnb-item" to="sub03_mindTherapy_02.html">
                              명상과 관상
                           </Link>
                           <Link className="sub-gnb-item" to="sub03_mindTherapy_03.html">
                              하루愛
                           </Link>
                           <Link className="sub-gnb-item" to="sub03_mindTherapy_04.html">
                              고백해
                           </Link>
                           <Link className="sub-gnb-item" to="sub03_mindTherapy_05.html">
                              EAP
                           </Link>
                        </div>
                     </div>
                  </div>
                  <div className="gnb-item">
                     <p className="gnb-menu">
                        사피엔자 아카데미아
                        <i className="icon"></i>
                     </p>
                     <div className="sub-gnb-wrap">
                        <div className="sub-gnb row-group">
                           <Link className="sub-gnb-item" to="sub04_sapienza_01.html">
                              교육소개
                           </Link>
                           <Link className="sub-gnb-item" to="sub04_sapienza_02.html">
                              3급 과정
                           </Link>
                           <Link className="sub-gnb-item" to="sub04_sapienza_03.html">
                              2급 과정
                           </Link>
                           <Link className="sub-gnb-item" to="sub04_sapienza_04.html">
                              1급 과정
                           </Link>
                           <Link className="sub-gnb-item" to="sub04_sapienza_05.html">
                              슈퍼바이저
                           </Link>
                        </div>
                     </div>
                  </div>
                  <div className="gnb-item">
                     <p className="gnb-menu">
                        상담사 · 센터소개
                        <i className="icon"></i>
                     </p>
                     <div className="sub-gnb-wrap">
                        <div className="sub-gnb row-group">
                           <Link className="sub-gnb-item" to="sub05_counselor.html">
                              상담사
                           </Link>
                           <Link className="sub-gnb-item" to="sub05_center.html">
                              상담·코칭센터
                           </Link>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="sns-btn-wrap col-group">
                  <Link to="" className="sns-btn">
                     <img src="/images/sns_btn_kc.png" alt="" />
                  </Link>
                  <Link to="" className="sns-btn">
                     <img src="/images/sns_btn_insta.png" alt="" />
                  </Link>
                  <Link to="" className="sns-btn">
                     <img src="/images/sns_btn_blog.png" alt="" />
                  </Link>
                  <Link to="" className="sns-btn">
                     <img src="/images/sns_btn_ytb.png" alt="" />
                  </Link>
                  <Link to="" className="sns-btn">
                     <img src="/images/sns_btn_ks.png" alt="" />
                  </Link>
                  <Link to="" className="sns-btn">
                     <img src="/images/sns_btn_band.png" alt="" />
                  </Link>
                  <Link to="" className="sns-btn">
                     <img src="/images/sns_btn_fb.png" alt="" />
                  </Link>
               </div>
            </div>
         </div>

         <div className="modal-container inquiry-modal" style={{ display: "none" }}>
            <div className="modal-wrap modal-alert-wrap">
               <i className="close-btn" onClick={() => document.querySelector('.inquiry-modal').style.display = 'none'}></i>
               <div className="modal-title-wrap">
                  <i className="icon red"></i>
                  <p className="title">상담사 등록 안내</p>
               </div>
               <p className="modal-alert-txt">
                  마인드 임팩트 상담사 등록을 원하시면 <br />
                  상담사 회원가입을 하셔야 합니다. <br />
                  아래 로그아웃 버튼을 누르시면 <br />
                  상담사 회원가입 페이지로 이동됩니다. <br />
                  지금 로그아웃 하시겠습니까?
               </p>
               <div className="modal-footer col-group">
                  <button className="modal-footer-btn gray">
                     로그아웃
                  </button>
               </div>
            </div>
         </div>
      </header>
   )
}

export default Header