import DOMPurify from 'dompurify'
import React, { useState } from 'react'
import { Link } from 'react-router-dom';

const PurchaseInfo = ({ info }) => {

   const [selectIndex, setSelectIndex] = useState(false);
   const [selectedItem, setSelectedItem] = useState("필수 옵션 선택");

   const handleSelect = () => {
      setSelectIndex(prev => !prev)
   }
   const selectItem = (selectedItem) => {
      setSelectedItem(selectedItem)
   }
   return (
      <div className="right-con">
         <div className="con-wrap">
            <div className="title-wrap">
               <p className="sub-title green">
                  {info.category}
               </p>
               <h2 className="title">{info.title}</h2>
               <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize((info.desc)) }} className="guide-txt"></p>
            </div>
            <div className="detail-list row-group">
               {info.orderSummary.map((item, index) => (
                  <div className="detail-item col-group" key={index}>
                     <p className={`item-default ${info.orderSummaryWidth === 140 ? "w140" : ""}`}>
                        <i className="icon">{item.icon}</i>
                        {item.title}
                     </p>
                     <p className="item-user">{item.content}</p>
                  </div>
               ))}
            </div>
            <div className="detail-item price-box col-group">
               <p className="item-default">기본가</p>
               <div className="item-user col-group">
                  <p className="percent">{info.price.discount}%</p> {/* 할인 퍼센트 */}
                  <p className="price">{(info.price.netPrice * info.price.discount / 100).toLocaleString()}원</p> {/* 할인된 가격 */}
                  <p className="before">{info.price.netPrice.toLocaleString()}원</p> {/* 기존가격 */}
               </div>
            </div>

            <div className="course-select-container">
               {info.options &&
                  <>
                     <div
                        onClick={() => handleSelect()}
                        className={`course-select-wrap ${selectIndex === true ? "active" : ''}`}
                     >
                        <div className="course-select col-group">
                           <div className="select-value">{selectedItem}</div>
                        </div>
                        <div className="option-list row-group">
                           {info.options.map((item, index) => (
                              <div
                                 onClick={() => selectItem(item)}
                                 className="option-item"
                                 key={index}
                              >
                                 {item}
                              </div>
                           ))}
                        </div>
                     </div>

                     <div className="prod-option-list row-group">
                        <div className="prod-option-item row-group">
                           <p className="prod-option-title">
                              마인드 인사이트 20개 묶음 상품 50%할인!(환불불가)
                           </p>
                           <div className="prod-option-group col-group">
                              <div className="prod-option-amount-group col-group">
                                 <button className="prod-option-amount-btn amount-minus">
                                    <i className="xi-minus"></i>
                                 </button>
                                 <span className="prod-option-amount-count">
                                    1
                                 </span>
                                 <button className="prod-option-amount-btn amount-plus">
                                    <i className="xi-plus"></i>
                                 </button>
                              </div>
                              <div className="col-group">
                                 <p className="prod-option-price">
                                    <strong>50,000</strong>원
                                 </p>
                                 <i className="xi-close-thin del-btn"></i>
                              </div>
                           </div>
                        </div>
                        <div className="prod-option-item row-group">
                           <p className="prod-option-title">
                              마인드 인사이트 + 마인드 리포트 (+ 20,000원)
                           </p>
                           <div className="prod-option-group col-group">
                              <div className="prod-option-amount-group col-group">
                                 <button className="prod-option-amount-btn amount-minus">
                                    <i className="xi-minus"></i>
                                 </button>
                                 <span className="prod-option-amount-count">
                                    1
                                 </span>
                                 <button className="prod-option-amount-btn amount-plus">
                                    <i className="xi-plus"></i>
                                 </button>
                              </div>
                              <div className="col-group">
                                 <p className="prod-option-price">
                                    <strong>140,000</strong>원
                                 </p>
                                 <i className="xi-close-thin del-btn"></i>
                              </div>
                           </div>
                        </div>
                     </div>
                  </>}
               <div className="price-box price-total-box col-group">
                  <p className="item-default">
                     총 상품 금액
                  </p>
                  <div className="item-user col-group">
                     <p className="amount">총 수량 3개</p>
                     <p className="price red">1,230,000원</p>
                  </div>
               </div>
            </div>
         </div>
         <div className="btn-wrap col-group">
            <button className="btn share-btn" type="button">
               <i></i>
            </button>
            <Link to="/order" className="btn submit-btn confirm">
               구매하기
            </Link>
         </div>
      </div>
   )
}

export default PurchaseInfo